const localweb="wortort";
export function getURL(n):string {
    let url = "https://w-ort.de/server/"+n;
    if( document.URL.includes("//localhost:30" ) )
        url = "http://localhost/wintermute/social_networks/"+localweb+"/server/"+n;
    return url;
}

export function px(x:number):string {
    return x+"px";
}
export function findNode(node:any,type:string):any{
    for(let c in node.children) {
        if( node.children[c].type===type) {
            return node.children[c];
        }
    }
    return undefined;
}

