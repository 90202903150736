import React from "react";
import {px} from "./helpers";

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons';


import Intro from "./Intro";
import KleeBlatt from "./KleeBlatt";

const iconTimes = <FontAwesomeIcon icon={faTimes} />
declare const window: any;
const animSpeed = 350*2;

interface DetailProps {
  kleeblatt:KleeBlatt;
  intro:Intro;
  id:string;
}

interface DetailState {
  detailnode:any;
  siblings:any;
  err:any;
  show:boolean;
}

class Detail extends React.Component <DetailProps, DetailState> {
  static scrollRef:any;
  static scrollPosX:number;
  static scrollPosY:number;
  static scrollStartX:number;
  static scrollStartY:number;
  static scrollEndX:number;
  static scrollEndY:number;
  static scrollEndStyleX:string;
  static scrollEndStyleY:string;
  static scrollTime:number;

  static lerp (start:number, end:number, t:number){
    t = (--t)*t*t*t*t+1;
    if( t > 1 ) t = 1;
    if( t < 0 ) t = 0;
    return  (1-t)*start+t*end;
  }

  static epsilon(a:number,b:number){
    return Math.abs(a - b )<=1;
  }

  constructor(props:DetailProps) {
      super(props);
      this.state={
        detailnode:[],
        siblings:[],
        err:[],
        show:false
      };
      this.animKleeblattDetail = this.animKleeblattDetail.bind(this);
      this.scrollKleeblattDetail = this.scrollKleeblattDetail.bind(this);
  }

  click(e:any,type:string){
    e.stopPropagation();
    switch(type) {
      case 'detail':
        this.scrollKleeblattDetail();
        break;
    }
  }

  animKleeblattDetail(){
    if( Detail.scrollRef ) {
      if(!Detail.epsilon(Detail.scrollPosX, Detail.scrollEndX )) {
        Detail.scrollPosX = Detail.lerp(Detail.scrollStartX, Detail.scrollEndX,(Date.now()-Detail.scrollTime)/animSpeed);
        Detail.scrollRef.style.left = Detail.scrollPosX + "px";
      }

      if( !Detail.epsilon(Detail.scrollPosY, Detail.scrollEndY )) {
        Detail.scrollPosY = Detail.lerp(Detail.scrollStartY, Detail.scrollEndY,(Date.now()-Detail.scrollTime)/animSpeed);
        Detail.scrollRef.style.top = Detail.scrollPosY + "px";
      }

      let xx = Math.abs(Detail.scrollPosX - Detail.scrollEndX );
      let yy = Math.abs(Detail.scrollPosY - Detail.scrollEndY );
      let d = Math.sqrt(xx*xx+yy*yy);

      if( !Detail.epsilon(d , 1) ) {
        window.requestAnimationFrame( this.animKleeblattDetail );
      }
      else {
        Detail.scrollRef.style.left = Detail.scrollEndStyleX;
        Detail.scrollRef.style.top = Detail.scrollEndStyleY;

        if( Detail.scrollRef.style.top != "0px"){
          this.setState({show:false,detailnode:undefined,siblings:undefined});
        }
        Detail.scrollRef = undefined;
      }
    }
  }
  closeKleeblattDetail() {
    if( this.state.show ) {
      this.scrollKleeblattDetail();
    }
  }
  scrollKleeblattDetail() {
    if( !Detail.scrollRef ) {
      if( !this.state.show ) {
        this.setState({show:true},this.scrollKleeblattDetail);
        return;
      }
      Detail.scrollRef = document.getElementById(this.props.id+"_detail");

      let r = Detail.scrollRef.getBoundingClientRect();

      Detail.scrollPosX = r.left;
      Detail.scrollPosY = r.top;

      Detail.scrollStartX = 0;
      Detail.scrollEndX = 0;
      Detail.scrollEndStyleX = px(0);

      if( Detail.epsilon(r.top, 0 ) && (r.height != 0) ) { // zuklappen
        Detail.scrollStartY = r.top;
        Detail.scrollEndY =  this.props.intro.state.vh;
        Detail.scrollEndStyleY = px(this.props.intro.state.vh);
      }
      else {  // aufklappen
        Detail.scrollStartY = this.props.intro.state.vh; //r.top;
        Detail.scrollEndY = 0;
        Detail.scrollEndStyleY = px(0);
      }

      Detail.scrollTime = Date.now();
      window.requestAnimationFrame(this.animKleeblattDetail);
    }
  }
  render() {
    // top:px(this.props.intro.state.vh),
        return (
          <>
              <div key={this.props.id+"_detail_container"}
                  className={"detail_0 zrb_backcolor4"}
                  id={this.props.id+"_detail"}
                  onClick={(e)=>this.click(e,"nop")}
                  style={{
                    left:px(0),
                    width:px(this.props.intro.state.vw),
                    height:px(this.props.intro.state.vh),
                    display:(this.state.show?"block":"none")}}
                      >
                <div className={"scrollerDetail zrb_backwhite"}
                  key={this.props.id+"_detail_scrollerN_50"}
                  onClick={(e)=>this.click(e,"nop")} >
                  {this.props.kleeblatt.getLeaf(this.state.detailnode,this.props.id+"_detail","")}
                </div>
                  <div key={this.props.id+"_detail_back"} className="detail_label_background zrb_backcolor3" onClick={(e)=>this.click(e,"detail")}/>
                  <div key={this.props.id+"_detail_text"} className="detail_label zrb_backcolor4" onClick={(e)=>this.click(e,"detail")}>
                      {this.state.detailnode?(<><span className="detail_headline">{this.state.detailnode.headline}</span>
                                                <span className="detail_headline_portrait">{this.state.detailnode.headline_portrait}</span>
                                              </>):""}
                  <div style={{position:"absolute", top:0, right:"20px"}}>{iconTimes}</div>
                  </div>
              </div>
          </>
        )
    }
}

export default Detail;