import React from "react";
import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditablePage from "./EditablePage";
import {getURL} from "./helpers";

interface NewBoxProps {
  editablepage:EditablePage;
}

interface NewBoxState {
  type:string;
  action_type:number;
  action_value:string;
}

class NewBox extends React.Component <NewBoxProps, NewBoxState> {
   constructor(props:NewBoxProps) {
        super(props);

        this.state = {
          type:'23',
          action_type:0,
          action_value:""
        };

        this.showAdd=this.showAdd.bind(this);
        this.addBox=this.addBox.bind(this);
        this.updateBox=this.updateBox.bind(this);
        this.deleteBox=this.deleteBox.bind(this);
        this.saveBox=this.saveBox.bind(this);
        this.close=this.close.bind(this);
    }
    componentDidMount() {
    }

    click(e:any){
      this.showAdd(true);
    }
    showAdd(s:boolean){
      this.props.editablepage.setState({showAddBox:s,box:undefined});
    }
    onChange(e:any){
      switch(e.target.id) {
        case 'type_1_3':
          this.setState({type:"13"});
          break;
        case 'type_2_3':
          this.setState({type:"23"});
          break;
        case 'type_3_3':
          this.setState({type:"33"});
          break;
      }
    }
    onActionChange(e:any) {
      switch(e.target.id) {
        case "action_1_3":
          this.setState({action_type:e.target.checked});
          break;
      }
    }
    onActionValueChange(e:any) {
      this.setState({action_value:e.currentTarget.value});
    }
    close() {
      this.showAdd(false);
    }
    addBox() {
      let ep:any = this.props.editablepage;
      let id = ep.findFirstUnusedId(ep.state.zeile.boxes);
      if( id ) {
        let action:any = this.state.action_value;
        if( !this.state.action_type)
          action=undefined;
        ep.state.zeile.boxes[id]=
          {"data":{
              "type": this.state.type,
              "content": "neue Box",
              "action": action,
              "xxsubtype": "headline"
            }};
        this.showAdd(false);
        ep.setState({data:ep.state.data});
      }
    }
    updateBox() {
      let ep:any = this.props.editablepage;
      let box=ep.state.box;
      let changed = false;
      if( this.state.type !== box.type){
        changed = true;
        box.type=this.state.type;
      }

      if( this.state.action_type !== box.action_type){
        changed = true;
        box.action_type=this.state.action_type;
      }
      if( this.state.action_value !== box.action_value){
        changed = true;
        box.action_value=this.state.action_value;
      }

      if( changed ) {
        this.saveBox();
      }
      //let id = ep.findFirstUnusedId(ep.state.zeile.boxes);
    }
    saveBox() {
      let ep:any = this.props.editablepage;
      let box=ep.state.box;

      let url = getURL("saveBox.php");

      fetch(url,{
          method: 'POST',
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(box)
        }
      ).then(function(response){
          return response.json();
        })
        .then(function(r) {
          ep.setState({showAddBox:false,box_edit_mode:'none',zeile:undefined,box:undefined});
        });
    }

    deleteBox() {
      let ep:any = this.props.editablepage;
      let id = ep.findFirstUnusedId(ep.state.zeile.boxes);
      if( id ) {
        let action:any = this.state.action_value;
        if( !this.state.action_type)
          action=undefined;
        ep.state.zeile.boxes[id]=
          {"data":{
              "type": this.state.type,
              "content": "neue Box",
              "action": action,
              "xxsubtype": "headline"
            }};
        this.showAdd(false);
        ep.setState({data:ep.state.data});
      }
    }

    render() {
        let ep:any = this.props.editablepage;
        return (
            <>
                <Modal show={ep.state.showAddBox}
                      onHide={()=>this.showAdd(false)}
                      dialogClassName="modal-90w">
                    <Modal.Header>
                      {ep.state.box_edit_mode==="new"?"Neuer Kasten":"Kasten bearbeiten"}
                    </Modal.Header>

                    <Modal.Body>
                        <div key={`boxtype`} className="mb-3">
                          Box-Typ: <>&nbsp;</>
                          <Form.Check
                            onChange={(e)=>this.onChange(e)}
                            checked={this.state.type==="13"?true:false}
                            inline
                            label="1/3"
                            name="group1"
                            type="radio"
                            id={`type_1_3`}
                          />
                          <Form.Check
                            onChange={(e)=>this.onChange(e)}
                            checked={this.state.type==="23"?true:false}
                            inline
                            label="2/3"
                            name="group1"
                            type="radio"
                            id={`type_2_3`}
                          />
                          <Form.Check
                            onChange={(e)=>this.onChange(e)}
                            checked={this.state.type==="33"?true:false}
                            inline
                            label="3/3"
                            name="group1"
                            type="radio"
                            id={`type_3_3`}
                          />
                      </div>

                      <div key={`actiontype`} className="mb-3">
                        <Form.Check
                          onChange={(e)=>this.onActionChange(e)}
                          checked={this.state.action_type!==0}
                          inline
                          label="Action"
                          name="group2"
                          type="checkbox"
                          id={`action_1_3`}
                        />
                       <input value={this.state.action_value} onChange={(e)=>this.onActionValueChange(e)}/>
                      </div>

                      <Button variant="outline-secondary" onClick={this.close} size="sm">Abbrechen</Button>
                      {ep.state.box_edit_mode==="new"?"":
                        (<Button variant="outline-danger" onClick={this.deleteBox} size="sm">Löschen</Button>)}

                      {ep.state.box_edit_mode==="new"?
                        (<Button variant="outline-success" onClick={this.addBox} size="sm">Hinzufügen</Button>):
                        (<Button variant="outline-info" onClick={this.updateBox} size="sm">Änderungen speichern</Button>)}

                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default NewBox;