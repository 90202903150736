import React from "react";
import {px} from "./helpers";

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons';


import Intro from "./Intro";
import KleeBlatt from "./KleeBlatt";

const iconTimes = <FontAwesomeIcon icon={faTimes} />
declare const window: any;
const animSpeed = 350*2;

interface MasterProps {
  kleeblatt:KleeBlatt;
  intro:Intro;
  id:string;
}

interface MasterState {
  masternode:any;
  err:any;
  show:boolean;
}

class Master extends React.Component <MasterProps, MasterState> {
  static scrollRef:any;
  static scrollPosX:number;
  static scrollPosY:number;
  static scrollStartX:number;
  static scrollStartY:number;
  static scrollEndX:number;
  static scrollEndY:number;
  static scrollEndStyleX:string;
  static scrollEndStyleY:string;
  static scrollTime:number;

  static lerp (start:number, end:number, t:number){
    t = (--t)*t*t*t*t+1;
    if( t > 1 ) t = 1;
    if( t < 0 ) t = 0;
    return  (1-t)*start+t*end;
  }
  static epsilon(a:number,b:number){
    return Math.abs(a - b )<=1;
  }

  constructor(props:MasterProps) {
      super(props);
      this.state={
        masternode:[],
        err:[],
        show:false
      };
      this.animKleeblattMaster = this.animKleeblattMaster.bind(this);
      this.scrollKleeblattMaster = this.scrollKleeblattMaster.bind(this);
  }

  click(e:any,type:string){
    e.stopPropagation();
    switch(type) {
      case 'master':
        this.props.kleeblatt.state.detailRef.current.closeKleeblattDetail();
        this.scrollKleeblattMaster();
        break;
    }
  }

  animKleeblattMaster(){
    if( Master.scrollRef ) {
      if(!Master.epsilon(Master.scrollPosX, Master.scrollEndX )) {
        Master.scrollPosX = Master.lerp(Master.scrollStartX, Master.scrollEndX,(Date.now()-Master.scrollTime)/animSpeed);
        Master.scrollRef.style.left = Master.scrollPosX + "px";
      }

      if( !Master.epsilon(Master.scrollPosY, Master.scrollEndY )) {
        Master.scrollPosY = Master.lerp(Master.scrollStartY, Master.scrollEndY,(Date.now()-Master.scrollTime)/animSpeed);
        Master.scrollRef.style.top = Master.scrollPosY + "px";
      }

      let xx = Math.abs(Master.scrollPosX - Master.scrollEndX );
      let yy = Math.abs(Master.scrollPosY - Master.scrollEndY );
      let d = Math.sqrt(xx*xx+yy*yy);

      if( !Master.epsilon(d , 1) ) {
        window.requestAnimationFrame( this.animKleeblattMaster );
      }
      else {
        Master.scrollRef.style.left = Master.scrollEndStyleX;
        Master.scrollRef.style.top = Master.scrollEndStyleY;
        if( Master.scrollRef.style.top != "0px"){
          this.setState({show:false,masternode:undefined});
        }
        Master.scrollRef = undefined;
      }
    }
  }

  scrollKleeblattMaster() {
    if( !Master.scrollRef ) {
      if( !this.state.show ) {
          this.setState({show:true},this.scrollKleeblattMaster);
          return;
      }
      Master.scrollRef = document.getElementById(this.props.id+"_master");

      let r = Master.scrollRef.getBoundingClientRect();

      Master.scrollPosX = r.left;
      Master.scrollPosY = r.top;

      Master.scrollStartX = 0;
      Master.scrollEndX = 0;
      Master.scrollEndStyleX = px(0);

      if( Master.epsilon(r.top, 0 ) && (r.height !== 0) ) { // zuklappen
        Master.scrollStartY = r.top;
        Master.scrollEndY =  this.props.intro.state.vh;
        Master.scrollEndStyleY = px(this.props.intro.state.vh);
      }
      else {  // aufklappen
        Master.scrollStartY = this.props.intro.state.vh; //r.top;
        Master.scrollEndY = 0;
        Master.scrollEndStyleY = px(0);
      }

      Master.scrollTime = Date.now();
      window.requestAnimationFrame(this.animKleeblattMaster);
    }
  }
  render() {
    // top:px(this.props.intro.state.vh),
        return (
          <>
              <div key={this.props.id+"_master_container"}
                  className={"master_0 zrb_backcolor4"}
                  id={this.props.id+"_master"}
                  onClick={(e)=>this.click(e,"nop")}
                  style={{
                    left:px(0),
                    width:px(this.props.intro.state.vw),
                    height:px(this.props.intro.state.vh),
                    display:this.state.show?"block":"none"}}
                  >
                <div className={"scrollerMaster zrb_backwhite"}
                  key={this.props.id+"_master_scrollerN_50"}
                  onClick={(e)=>this.click(e,"nop")} >
                  {this.props.kleeblatt.getLeaf(this.state.masternode,this.props.id+"_master","")}
                </div>
                  <div key={this.props.id+"_master_back"} className="master_label_background zrb_backcolor3" onClick={(e)=>this.click(e,"master")}/>
                  <div key={this.props.id+"_master_text"} className="master_label zrb_backcolor4" onClick={(e)=>this.click(e,"master")}>
                      {this.state.masternode?(<><span className="master_headline">{this.state.masternode.headline}</span>
                        <span className="master_headline_portrait">{this.state.masternode.headline_portrait}</span>
                        </>):""}

                      <div style={{position:"absolute", top:0,right:"20px"}}>{iconTimes}</div>
                  </div>
              </div>
          </>
        )
    }
}

export default Master;