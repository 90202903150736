import React from "react";
import "../css/pageeditor.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import sha256 from "../sha256";
import PageCanvas from "./PageCanvas";
const iconTimes = <FontAwesomeIcon icon={faTimes} />
const iconLeft = <FontAwesomeIcon icon={faArrowLeft} />
const iconRight = <FontAwesomeIcon icon={faArrowRight} />

declare const window: any;

const FUNC_DELETEPAGE=1;

interface PageEditorProps {
}

interface PageEditorState {
    show_modal:boolean;
    show_titel:any;
    show_text:any;
    pages:any;
    currentPage:any;

    dialog_show:boolean;
    dialog_title:string;
    dialog_text:string;
    dialog_data:any;
}
const ESCAPE_KEY = 27;
// Einstellungen
// seiten [1,2,3,4,5,6,7,8,...]
//     (seiten höhe, breite?)
//     element addieren, seite löschen

class PageEditor extends React.Component <PageEditorProps, PageEditorState> {
    public next=0;
    constructor(props:PageEditorProps) {
        super(props);
        this.state = {
            show_titel:"Seiten",
            show_text:"",
            show_modal:true,
            pages:[],
            currentPage:undefined,
            dialog_show:false,
            dialog_title:"title",
            dialog_text:"text",
            dialog_data:{}
        };
        this.handleKeyDown=this.handleKeyDown.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.addPage=this.addPage.bind(this);
        this.closeDialog=this.closeDialog.bind(this);
        this.showDialog=this.showDialog.bind(this);
    }

    handleKeyDown = (event:any) => {
        switch( event.keyCode ) {
            case ESCAPE_KEY:
                this.setState({show_modal:true});
                break;
            default:
                break;
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    addPage(e:any){
        this.state.pages.push({pageidx:this.state.pages.length,
            pageno:(this.state.pages.length+""),
            id:sha256((Date.now()+this.next++)+"sddsfsdfsdflkewjrlkwejrlkwjerl"),
            tagline:"",
            titel:"",
            untertitel:"",
            text:"",
            footer:""});
        this.setState({pages:this.state.pages});
    }

    setCurrentPage(e:any,page:any){
        this.setState({currentPage:page});
    }

    addText(e:any){
    }

    deletePage(pageIDX:number){
        this.showDialog("Seite löschen?",
                        "Soll die Seite und deren Elemente gelöscht werden?",
                        {function:FUNC_DELETEPAGE,data:pageIDX});
    }

    handleClick(e:any) {
        switch (e.detail) {
            case 1:
                console.log("click");
                break;
            case 2:
                this.setState({show_modal:true});
                break;
        }
    };
    closeModal() {
        this.setState({show_modal:false});
    }
    buildPage(page:any):any{
            return (<PageCanvas page={page}/>)
    }
    showDialog(title:string,text:string,data:any) {
        this.setState({dialog_show:true,
                        dialog_title:title,
                        dialog_text:text,
                        dialog_data:data});
    }
    closeDialog() {
        this.setState({dialog_show:false});
    }
    yesDialog(e:any) {
        switch(this.state.dialog_data.function) {
            case FUNC_DELETEPAGE:{
                let removed = this.state.pages.splice(this.state.dialog_data.data,1);
                let idx=0;
                for(let p in this.state.pages ) {
                    this.state.pages[p].pageidx=idx;
                    this.state.pages[p].pageno=idx+"";
                    idx++;
                }
                this.setState({pages:this.state.pages,
                                dialog_data:{}
                        });
            }
        }
        this.closeDialog();
    }
    noDialog(e:any) {
        this.closeDialog();
    }
    render() {
        let editing:any=[];
        let display:any=[];
        let p:string;

        for(p in this.state.pages) {
            let page=this.state.pages[p];

            display.push(this.buildPage(page));

            let inner=undefined;
            if( this.state.currentPage )
                if( p===this.state.currentPage.pageno ){
                    inner = (<div className={"page_current"}></div>);
                }

            editing.push(
            <div className={"page_placeholder"} onClick={(e:any)=>this.setCurrentPage(e,page)}><br/><br/>{page.pageno}
              <div className={"page_delete"} onClick={(e:any)=>this.deletePage(page.pageidx)}></div>
              {inner}
            </div>);
        }

        return (
                <>
                <div key={"lkjlj"}
                    className={"pageeditor"}
                    onClick={this.handleClick}
                      >
                      {display}
                </div>

                <Modal show={this.state.show_modal}
                    onHide={()=>this.closeModal()}
                    dialogClassName="modal-75w">
                    <Modal.Header style={{padding:0}}>
                        <div style={{position:"absolute",left:"10px",top:"25px"}}>{iconLeft}</div>
                        <div style={{position:"absolute",right:"10px",top:"25px"}}>{iconRight}</div>
                        <h1><>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>{this.state.show_titel}</h1>
                        <div style={{position:"absolute",right:"10px",top:"0px"}} onClick={(e)=>{this.closeModal()}}>{iconTimes}</div>
                        <Button className= {"add_page"} variant="success" onClick={(e:any)=>this.addPage(e)}>+</Button>
                    </Modal.Header>

                    <Modal.Body style={{padding:0}}>
                        {editing}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className= {"add_element"} variant="info" onClick={(e:any)=>this.addText(e)}>T</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.dialog_show}
                    onHide={()=>this.closeDialog()}
                    dialogClassName="modal-50w">

                    <Modal.Header style={{padding:0}}>
                        <h1><>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>{this.state.dialog_title}</h1>
                        <div style={{position:"absolute",right:"10px",top:"0px"}} onClick={(e)=>{this.closeModal()}}>{iconTimes}</div>
                    </Modal.Header>

                    <Modal.Body style={{padding:0}}>
                        {this.state.dialog_text}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={(e:any)=>this.noDialog(e)}>Nein</Button>
                        <Button variant="success" onClick={(e:any)=>this.yesDialog(e)}>Ja</Button>
                    </Modal.Footer>
                </Modal>

                </>
            )
    }
}

export default PageEditor;