import React from "react";
import Froala from "./Froala";
import NewBox from "./NewBox";

import './css/EditablePage.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import { faRemove } from '@fortawesome/free-solid-svg-icons'
import {faAdd, faEdit} from '@fortawesome/free-solid-svg-icons'
import {getURL} from './helpers';
import KleeBlatt from "./KleeBlatt";
import Girlande from "./Girlande";

//const iconRemove = <FontAwesomeIcon icon={faRemove} />
const iconEdit = <FontAwesomeIcon icon={faEdit} />
const iconAdd = <FontAwesomeIcon icon={faAdd} />

interface EditablePageProps {
    data:any,
    kleeblatt:KleeBlatt,
    keyName:string
}

interface EditablePageState {
    showAddBox:boolean,
    box_edit_mode:string,
    zeile:any,
    box:any,
    newBoxRef:any
}

class EditablePage extends React.Component <EditablePageProps, EditablePageState> {
    constructor(props:EditablePageProps) {
        super(props);

        this.state = {
            showAddBox:false,
            box_edit_mode:'new',
            zeile:undefined,
            box:undefined,
            newBoxRef:React.createRef()
        };
        this.buildPage=this.buildPage.bind(this);
        this.action=this.action.bind(this);
    }
    componentDidMount() {
        window.document.addEventListener("keydown",this.keydown);
        window.document.addEventListener("keyup",this.keyup);
    }
    keydown(e:any){
        if(e.key==="Escape") {
            let el:any = document.querySelector( ':focus' );
            if( el ) el.blur();
        }
    }
    keyup(e:any){
    }

    isEmpty(obj:any) {
        return Object.keys(obj).length === 0;
    }
    action(a:any){
        if( a.id_tag_call ) {
            this.props.kleeblatt.menuRef.current.actionMenu(a,true);
        }
        else
        if( a.action_command === 'newsletter') {
            this.props.kleeblatt.menuRef.current.setState({showSubscribeForm:true});
        }
        else  if( a.action_command === 'target_blank') {
            window.open(a.action_href,"_blank");
        }
        else  if( a.action_command === 'target_self') {
            window.open(a.action_href,"_self");
        }
        else
            alert(a);
    }
    buildBox(zeile:any,box:any):any {
        let cls="";
        if(box.cls) {
            cls = " "+box.cls+" ";
        }

        if( box.type === "lang_girlande") {
            return (<div className={"box"+box.subtype +" "+box.type+cls} key={box.id+"_box"}>
                <Girlande/>
                </div>);
        }

        if( box.type === "headline") {
            let nopin = {'nopin': 'nopin'}
            return (<img {...nopin} alt={""} className={"headline box"+box.subtype+cls} src={box.src} key={box.id+"_image"}/>);
        }
        if( box.type === "stoerer_landscape") {
            let nopin = {'nopin': 'nopin'}
            return (<img {...nopin} alt={""} className={"stoerer_landscape"+cls} src={box.src} key={box.id+"_image"}/>);
        }
        if( box.type === "stoerer_portrait") {
            let nopin = {'nopin': 'nopin'}
            return (<img {...nopin} alt={""} className={"stoerer_portrait"+cls} src={box.src} key={box.id+"_image"}/>);
        }
        if( box.type === "image") {
            let nopin = {'nopin': 'nopin'};
            let img = (<img {...nopin} alt={""} className={"box"+box.subtype+cls} src={box.src} key={box.id+"_image"}/>);

            if( box.href ) {
                return (<a href={box.href} target={"_blank"}>{img}</a>);
            }
            return img;
        }
        if( box.type === "image4") {
            let nopin = {'nopin': 'nopin'}
            return (<img {...nopin} alt={""} className={"box"+box.subtype+cls} src={box.src} key={box.id+"_image"}/>);
        }
        if( box.type === "link") {
            return (<div className="link" onClick={(e:any)=>this.action(box)}>{box.text}</div>);
        }
        let action:any=undefined;
        if( box.action_type ) {
            action = (<><br/><div className={"action_button"} onClick={(e)=>this.action(box)} key={box.id+"_action"} dangerouslySetInnerHTML={{__html: box.action_value}}/></>)
        }
        let rtl="ltr";
        if(box.rtl ) {
            rtl="rtl";
        }
        return (<div className={"box"+box.subtype +" "+box.type+cls} key={box.id+"_box"}>
          {this.props.kleeblatt.darfEditieren()?
                <>
                    <Froala editablePage={this} cls={box.type?box.type:"text_box"} key={box.id+"_imnplace"} id={box.id} html_contents={box.text}/>
                    <div style={{position:"absolute",right:"0px", top:"0px", color:"#000000",zIndex:"1005",cursor:"pointer"}} onClick={(e:any)=>this.editBox(box)} key={box.id+"_edit"}>{iconEdit}</div>
                </>
                :
                <div className={box.type?box.type:"text_box"+cls} key={box.id+"_view"} dangerouslySetInnerHTML={{__html: box.text}} dir={rtl}/>
            }
        {action}
        </div>);
    }
    findFirstUnusedId(o:any){
        for(let i=1;i<=999;i++) {
            let s:string = i+"";
            while(s.length<3) s="0"+s;
            if( !o[s]){
                return s;
            }
        }
        return undefined;
    }
    addBox(zeile:any) {
        let url = getURL("addBox.php");
        fetch(url,{
              method: 'POST',
              headers : {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              },
              body: JSON.stringify(zeile.data)
          }
        ).then(function(response){
            return response.json();
        })
          .then(function(r) {
              //ep.setState({showAddBox:false,box_edit_mode:'none',zeile:undefined,box:undefined});
          });

        this.setState({showAddBox:true,box_edit_mode:'new',zeile:zeile,box:undefined});
    }
    editBox(box:any) {
        this.state.newBoxRef.current.setState({
        action_type:!!(box.action_type),
        action_value:box.action_value,
        type:box.type});
        this.setState({showAddBox:true, box_edit_mode:'edit',zeile:undefined,box:box});
    }
    removeBox(zeile:any,box:any) {
        for( let s in zeile.boxes) {
            if(zeile.boxes[s]===box) {
                delete zeile.boxes[s];
                if( this.isEmpty(zeile.boxes) ) {
                    //delete zeile;

                }
                break;
            }
        }
        //this.setState({data:this.state.data});
    }
    editZeile(zeile:any) {
        alert("editZeile");
    }
    buildZeile(zeile:any):any {
        let s=[];

        for(let child in zeile.children){
            if( zeile.children[child] )
                s.push(this.buildBox(zeile,zeile.children[child]));
        }
        if( s.length===0 ) {
            s.push(<><br/><br/></>);
        }
        return (<div className="zeile" key={zeile.id}>{s}
        {
            this.props.kleeblatt.darfEditieren()?
              <>
                <div style={{position:"absolute",bottom:0,right:"100px"}} key={zeile.id+"_box"}>
                    <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.editZeile(zeile)} key={zeile.id+"_edit"}>{iconEdit}</div>
                    <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.addBox(zeile)} key={zeile.id+"_add"}>{iconAdd}</div>
                </div>
              </>:
                ""
        }
        </div>);
    }
    buildFooter(footer:any):any {
        let s=[];

        for(let child in footer.children){
            if( footer.children[child] )
                s.push(this.buildBox(footer,footer.children[child]));
        }
        if( s.length===0 ) {
            s.push(<><br/><br/></>);
        }
        return (<div className="footer" key={footer.id}>{s}
        {
            this.props.kleeblatt.darfEditieren()?
              <>
                <div style={{position:"absolute",bottom:0,right:"100px"}} key={footer.id+"_box"}>
        <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.editZeile(footer)} key={footer.id+"_edit"}>{iconEdit}</div>
          <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.addBox(footer)} key={footer.id+"_add"}>{iconAdd}</div>
          </div>
          </>:
            ""
        }
        </div>);
    }
    buildCredits(credits:any):any {
        let s=[];

        s.push(<hr key={credits.id+"_hr"} />);
        for(let child in credits.children){
            if( credits.children[child] )
                s.push(this.buildZeile(credits.children[child]));
        }
        if( s.length===0 ) {
            s.push(<><br/><br/></>);
        }
        return (<div className="credits" key={credits.id}>{s}
        {
            this.props.kleeblatt.darfEditieren()?
              <>
                <div style={{position:"absolute",bottom:0,right:"100px"}} key={credits.id+"_box"}>
                    <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.editZeile(credits)} key={credits.id+"_edit"}>{iconEdit}</div>
                    <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.addBox(credits)} key={credits.id+"_add"}>{iconAdd}</div>
                </div>
              </>:
            ""
        }
        </div>);
    }
    addZeile(box:any) {
        alert("add zeile")
    }

    buildPage():any {
        if( !this.props.data ) return;
        let z:any=[];

        for(let n in this.props.data.children){
            let node = this.props.data.children[n];
            switch(node.type) {
                case 'zeile':
                    z.push(this.buildZeile(node));
                    break;
                case 'credits':
                    z.push(this.buildCredits(node));
                    break;
                case 'footer':
                    z.push(this.buildFooter(node));
                    break;
            }
        }
        let tools =
          this.props.kleeblatt.darfEditieren()?
            <div style={{position:"absolute",right:"0px", top:"0px", zIndex:"1005",cursor:"pointer",color:"#808080"}} onClick={(e:any)=>this.addZeile(this.props.data.zeilen)}>{iconAdd}</div>
        :
        undefined;
        if( tools )
            z.push(tools);
        z.push(<><br/><br/><br/><br/></>);
        return <div key={this.props.keyName}className="pagecontainer">{z}</div>;
    }

    render() {
        return (<>{this.buildPage()}<NewBox ref={this.state.newBoxRef} editablepage={this} /></>)
    }
}

export default EditablePage;