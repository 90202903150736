import React from "react";

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

declare const window: any;

interface GirlandeProps {
}

interface GirlandeState {
}

class Girlande extends React.Component <GirlandeProps, GirlandeState> {

    render() {
        return (
          <>
            <span className={"fi fi-gb fir fibo"} style={{width:"4em",height:"3em"}}></span>
            <span className={"fi fi-fr fir fibo"} style={{width:"4em",height:"3em"}}></span>
            <span className={"fi fi-ir fir fibo"} style={{width:"4em",height:"3em"}}></span>
            <span className={"fi fi-ru fir fibo"} style={{width:"4em",height:"3em"}}></span>
            <span className={"fi fi-sy fir fibo"} style={{width:"4em",height:"3em"}}></span>
            <span className={"fi fi-tr fir fibo"} style={{width:"4em",height:"3em"}}></span>
            <span className={"fi fi-ua fir fibo"} style={{width:"4em",height:"3em"}}></span>
            <span className={"fi fi-de fir fibo"} style={{width:"4em",height:"3em"}}></span>
          </>
        )
    }
}

export default Girlande;