import React from "react";
import KleeBlatt from "./KleeBlatt";
import {findNode} from "./helpers";

import FloatingMenu from "./FloatingMenu";

interface IntroProps {
}

interface IntroState {
    kleeblattRef:any;
    loaded:boolean;
    command:string;
    vw:number;
    vh:number;
}

const animSpeed:number=2*350;

class Intro extends React.Component <IntroProps, IntroState> {
    static scrollRef:any;
    static scrollPosX:number;
    static scrollPosY:number;
    static scrollStartX:number;
    static scrollStartY:number;
    static scrollEndX:number;
    static scrollEndY:number;

    static scrollEndStyleX:string;
    static scrollEndStyleY:string;

    static scrollTime:number;

    static queryString:string;
    static urlParams:any;

    constructor(props:IntroProps) {
        super(props);

        Intro.queryString = window.location.search;
        Intro.urlParams = new URLSearchParams(Intro.queryString);

        this.state = {
            kleeblattRef:React.createRef(),
            loaded:false,
            vw:0,
            vh:0,
            command:""
        };
        this.handleResize=this.handleResize.bind(this);
        this.onpopstate=this.onpopstate.bind(this);
        this.navigateToCommand=this.navigateToCommand.bind(this);

        Intro.scrollRef=undefined;
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        window.onpopstate = this.onpopstate;

        let cmd = window.location.pathname;
        if( cmd.startsWith("/"))
            cmd = cmd.substring(1);
        this.setState({command:cmd});
    }
    navigateToCommand(cmd:string){
        if( cmd) {
            let command=this.state.kleeblattRef.current.findActionCommandParameter(cmd);
            if( command ) {
                let contents=findNode(command,"contents");
                if( contents ) {
                    this.state.kleeblattRef.current.menuRef.current.showHTMLText(command.text,contents.text);
                    return true;
                }
            }
            if( cmd !== "err404") { // rekursion verhindern
                this.navigateToCommand("err404");
            }
        }
        return false;
    }

    onpopstate(event:any){
        if( event.state) {
            let command=this.state.kleeblattRef.current.findActionCommandParameter(event.state.action_command_parameter);
            if( command ) {
                let contents=findNode(command,"contents");
                if( contents ) {
                    this.state.kleeblattRef.current.menuRef.current.showHTMLText(command.text,contents.text);
                    return;
                }
            }
        }

        let cmd = window.location.pathname;
        if( cmd.startsWith("/"))
            cmd = cmd.substring(1);

        if( cmd ) {
            this.navigateToCommand(cmd);
        }
        else {
            this.state.kleeblattRef.current.menuRef.current.showHTMLText("",""); // fenster schlissen
        }
    }
    force() {

        if( this.state.kleeblattRef.current ) {
            let kleeblatt = this.state.kleeblattRef.current;
            if( kleeblatt.state.masterRef ) {
                if ( kleeblatt.state.masterRef.current ) {
                    kleeblatt.state.masterRef.current.forceUpdate();
                }
            }
            if( kleeblatt.state.detailRef ) {
                if ( kleeblatt.state.detailRef.current ) {
                    kleeblatt.state.detailRef.current.forceUpdate();
                }
            }
        }
    }
    handleResize() {
        this.setState({vh:window.innerHeight,
            vw:window.innerWidth}, this.force );
    }

    showAnmelden(e:any){
        Intro.scrollPageUp("anmelden");
    }

    static lerp (start:number, end:number, t:number){
        t = (--t)*t*t*t*t+1;
        if( t > 1 ) t = 1;
        if( t < 0 ) t = 0;
        return  (1-t)*start+t*end;
    }

    static scrollPageUpAnim(){
        if( Intro.scrollRef ) {
            if(Intro.scrollPosY > 0 ) {
                Intro.scrollPosY=Intro.lerp(Intro.scrollStartY,Intro.scrollEndY,(Date.now()-Intro.scrollTime)/animSpeed);
                Intro.scrollRef.style.top = Intro.scrollPosY + "px";
                window.requestAnimationFrame(Intro.scrollPageUpAnim);
            }
            else {
                Intro.scrollPosY=0;
                Intro.scrollRef.style.top = Intro.scrollPosY + "px";
                Intro.scrollRef = undefined;
            }
        }
    }

    static scrollPageUp(animid:string){
        if( !Intro.scrollRef ) {
            Intro.scrollRef = document.getElementById(animid);
            Intro.scrollPosY = window.innerHeight;
            Intro.scrollRef.style.top = Intro.scrollPosY + "px";
            Intro.scrollRef.style.display = "block";
            Intro.scrollTime = Date.now();
            Intro.scrollStartY = window.innerHeight;
            Intro.scrollEndY = 0;
            window.requestAnimationFrame(this.scrollPageUpAnim);
        }
    }

    static scrollPageDownAnim(){
        if( Intro.scrollRef ) {
            if(Intro.scrollPosY < window.innerHeight ) {
                Intro.scrollPosY=Intro.lerp(Intro.scrollStartY,Intro.scrollEndY,(Date.now()-Intro.scrollTime)/animSpeed);
                Intro.scrollRef.style.top = Intro.scrollPosY + "px";
                window.requestAnimationFrame(Intro.scrollPageDownAnim);
            }
            else {
                Intro.scrollPosY=window.innerHeight;
                Intro.scrollRef.style.top = "100%";//Intro.scrollPos + "px";
                Intro.scrollRef.style.display = "none";//Intro.scrollPos + "px";
                Intro.scrollPosY=0;
                Intro.scrollRef = undefined;
            }
        }
    }

    static scrollPageDown(animid:string){
        if( !Intro.scrollRef ) {
            Intro.scrollRef = document.getElementById(animid);
            Intro.scrollPosY = 0;
            Intro.scrollTime = Date.now();
            Intro.scrollStartY = 0;
            Intro.scrollEndY = window.innerHeight;
            window.requestAnimationFrame(this.scrollPageDownAnim);
        }
    }
    // <FloatingMenu kleeblattRef={this.state.kleeblattRef}/>
    render() {
        return (
            <div className="intro">
                <KleeBlatt ref={this.state.kleeblattRef}
                    intro={this}
                    leafs={"MF"}
                    id={"kleeblatt"}/>

                <div className="loading" style={{position:"relative",display:this.state.loaded?"none":"block"}}>
                     <img style={{position:"absolute",right:"10px", bottom:"10px"}} alt={""} src="./bilder/ladedaten.svg" height={20}/>
                </div>
            </div>
        )
    }
}

export default Intro;