import React from "react";

declare const window: any;

interface PageCanvasProps {
    page:any;
}

interface PageCanvasState {
}

class PageCanvas extends React.Component <PageCanvasProps, PageCanvasState> {
    constructor(props:PageCanvasProps) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }

    initMap() {

    }

    render() {
        return (
            <div key={this.props.page.id+":page"}  className={"pagecanvas"}>
                {this.props.page.id + " " + this.props.page.pageno }

                <div style= {{position:"absolute",left:"10px",top:"20px",width:"50%",height:"75%",backgroundColor:"#00ff0f"}}> oisd opupoqwu peoqwipeoiqwpoeiq wpoeiqwpo eiqwpoei pqoweipoqwipoqwei qpwoei </div>
            </div>
        )
    }
}

export default PageCanvas;