import React from "react";
import EditablePage from "./EditablePage";
import {getURL} from "./helpers";

let save_url = getURL("saveBox.php");
let image_url = getURL("upload_image.php");
let video_url  = getURL("/upload_video.php");

const editorOptions= {
  initOnClick: true,
  toolbarInline: true,
  charCounterCount: false,

  // Colors list.
  colorsBackground: [
  '#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE',
  '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'
  ],
  colorsStep: 6,
  colorsText: [
  '#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE',
  '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'
  ],
  placeholderText: 'Gib deinen Text hier ein!',
  attribution: false,
  autofocus: true,
  fontSizeSelection: true,
  toolbarVisibleWithoutSelection:true,
  language: 'de',
  imageEditButtons: ['imageDisplay', 'imageAlign', 'imageInfo', 'imageRemove'],
  key: "te1C2sD7C6A5C5F4D4C3kOPVe1f1d1Le1b1D1AWMSGSAFDTGHWsF4I4A11D9C2B5G5B1E3I3==",
  quickInsertEnabled: false,
  toolbarButtons: {
    'moreText': {
      'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
    },

    'moreParagraph': {
      'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
    },

    'moreRich': {
      'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
      'buttonsVisible': 9
    },

    'moreMisc': {
      'buttons': ['undo', 'redo', 'selectAll', 'html', 'help'],
      'align': 'right',

      'buttonsVisible': 5
    },
    'inlineClass':{
      'fr-class-ueberschrift': 'Überschrift',
    },
  },

  // ------
  saveMethod: 'POST',
  saveURL: save_url,
  saveParams: {
    id: 'my_editor',
    access_token: ""
  },
  saveInterval: 2500,
  saveParam: 'content',
  // -------
   imageUploadParam: 'image_param',
   imageUploadURL: image_url,
   imageUploadParams:{id: 'my_editor'},
   imageUploadMethod:'POST',
   imageMaxSize:200 * 1024 * 1024,
   imageAllowedTypes:['png', 'jpg','svg'],

  // -------
  videoUploadParam: 'video_param',
  videoUploadURL: video_url,
  videoUploadParams: {id: 'my_editor'},
  videoUploadMethod: 'POST',
  videoMaxSize: 200 * 1024 * 1024,
  videoAllowedTypes: ['mp4', 'mov'],
}

declare class FroalaEditor {
  constructor(a: any,b:any);
  static DefineIcon(a:any,b:any):void;
  static RegisterCommand(a:any,b:any):void;
}
FroalaEditor.DefineIcon('imageInfo', {NAME: 'info', SVG_KEY: 'help'});
FroalaEditor.RegisterCommand('imageInfo', {
  title: 'Info',
  focus: true,
  undo: true,
  refreshAfterCallback: false,
  callback: function () {
    let $img = this.image.get();
    alert($img.attr('src'));
  }
});

interface FroalaProps {
  cls:string;
  html_contents:string;
  id:string;
  children?: React.ReactNode;
  editablePage?:EditablePage;
}

interface FroalaState {
  froalaRef:any;
  editor:any;
  saved:boolean;
}

class Froala extends React.Component <FroalaProps, FroalaState> {
    constructor(props:FroalaProps) {
        super(props);
        this.state = {
          froalaRef: React.createRef(),
          editor: undefined,
          saved:true
        };
    }
    componentDidMount() {
      if( this.state.froalaRef.current ) {
        let eo = JSON.parse(JSON.stringify(editorOptions));
        let app = this;
        eo.events= {
          'contentChanged': function () {
            app.setState({saved:false});
          },
          'focus': function () {
          },
          'blur': function () {
            if( !app.state.saved ) {
              app.state.editor.save.save();
            }
          },
          'click': function () {
          },
          'save.before': function () {
          },

          'save.after': function () {
            app.setState({saved:true});
          },

          'save.error': function () {

          },
          // ----
          'image.beforePasteUpload': function (img:any) {
          },
          'image.beforeRemove': function ($img:any) {
          },
          'image.beforeUpload': function (images:any) {
          },
          'image.hideResizer': function (images:any) {
            // Do something here.
            // this is the editor instance.
          },
          'image.error': function (error:any, response:any) {
          },
          'image.inserted': function ($img:any, response:any) {
          },
          'image.loaded': function ($img:any) {
          },
          'image.removed': function ($img:any) {
          },
          'image.replaced': function ($img:any, response:any) {
          },
          'image.resize': function ($img:any) {
          },
          'image.resizeEnd': function ($img:any) {
          },
          'image.uploaded': function (response:any) {
          },
          'image.uploadedToS3': function (link:any, key:any, response:any) {
          },
          'imageManager.beforeDeleteImage': function ($img:any) {
          },
          'imageManager.error': function (error:any, response:any) {
          },
          'imageManager.imageDeleted': function (data:any) {
          },
          'imageManager.imageLoaded': function ($img:any) {
          },
          'imageManager.imagesLoaded': function (data:any) {
          },
          // ----
          'video.codeError': function (code:any) {
          },
          'video.inserted': function ($video:any) {
          },
          'video.replaced': function ($video:any) {
          },
          'video.linkError': function (link:any) {
          },
          'video.removed': function ($video:any) {
          },
          'video.loaded': function ($video:any) {
          },
          'video.uploaded': function (response:any) {
          },
          'video.uploadedToS3': function (link:any, key:any, response:any) {
          },
          'video.beforeUpload': function ($video:any) {
          },
          'video.beforeRemove': function ($video:any) {
          },
          'video.hideResizer': function () {
          }

        };

        if(this.props.editablePage) {
          eo.saveParams.access_token=this.props.editablePage.props.kleeblatt.state.access_token;
        }
        eo.saveParams.id=this.props.id;
        let editor:any = new FroalaEditor(this.state.froalaRef.current, eo);
        this.setState({editor:editor});
      }
    }

    render() {
        return (
            <>
              <div className={'myFroala '+this.props.cls} ref={this.state.froalaRef}
                dangerouslySetInnerHTML={{__html: this.props.html_contents}}>
                  {this.props.children}
              </div>
              <div style={{position:"absolute",right:"30px",top:"0", color:"#ff0000"}}>{this.state.saved?"":"*"}</div>
            </>
        )
    }
}

export default Froala;