import React, {RefObject} from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import Intro from "./Intro";
import 'bootstrap/dist/css/bootstrap.min.css';

const introRef:RefObject<Intro> = React.createRef();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
      <Intro ref={introRef}/>
)
