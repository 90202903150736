import React from "react";

declare const window: any;

interface MapProps {
    map_id:string;
    orientation:string;
    gap:string;
}

interface MapState {
    map:any;
    mapRef:any;
}

class Map extends React.Component <MapProps, MapState> {
    constructor(props:MapProps) {
        super(props);
        this.state = {
            map:undefined,
            mapRef:React.createRef()
        };

        this.initMap = this.initMap.bind(this);
        window.initMap = this.initMap;
    }
    //AIzaSyD5uJghInxzhZXkwnLZh_Q6ZyO1C16doGU

    componentDidMount() {
        if( !this.state.map ) {
            if( this.state.mapRef.current ) {
                this.state.mapRef.current.style.background="#ffffff";
                if( google ) {
                    let map = new google.maps.Map(this.state.mapRef.current, {
                        center: { lat: 51.9500854, lng: 7.6240971 },
                        zoom: 18,
                        mapTypeId: 'satellite'
                    });
                    this.setState({map:map});
                }
            }
        }
    }

    initMap() {
    }

    render() {
        return (
            <div key={this.props.map_id} id={this.props.map_id} ref={this.state.mapRef} className={"map_"+this.props.orientation+"_"+this.props.gap}>
            </div>
        )
    }
}

export default Map;