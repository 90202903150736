import React from "react";
// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import Intro from "./Intro";
import {px} from "./helpers";

declare const window: any;

interface VideoProps {
  intro:Intro;
  url:string;
  vert:number;
  horz:number;
}

interface VideoState {
}

class Video extends React.Component <VideoProps, VideoState> {
  render() {
    let w = this.props.intro.state.vw;
    let h = this.props.intro.state.vh;
    if( this.props.horz>this.props.vert) { // landscape

    }
    else{ // porttrait

    }

    return (
      <div
        style={{position:"absolute",
                top:px(50) ,
                width:px(this.props.intro.state.vw),
                height:px(this.props.intro.state.vh-100)}}>

        <div style={{position:"absolute",
                top:px(0) ,
                left:px(0) ,
                width:px(this.props.intro.state.vw),
                height:px(this.props.intro.state.vh)}}>
          <video className="video_video"
                width={1920*0.6}
                height={1080*0.6} controls>
            <source src={this.props.url} type={"video/mp4"}/>
            Ihr Browser kann keine Videos abspielen.
          </video>
        </div>
      </div>
  )
  }
}

export default Video;