import React from "react";

import Intro from "./Intro";
import KleeblattEinstellungen from "./KleeblattEinstellungen";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons'

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import KleeBlatt from "./KleeBlatt";
import {findNode} from "./helpers";
import NewsLetter from './NewsLetter';

const iconHamburger = <FontAwesomeIcon icon={faBars} />
const iconTimes = <FontAwesomeIcon icon={faTimes} />

const MENU_ITEM=0;
const MENU_SUB_ITEM=1;
const MENU_ITEM_SMALL=2;
const MENU_TEXT=3;

const animSpeed = 350*2;

interface KleeblattMenuProps {
  data:any;
  kleeblatt:KleeBlatt;
}

interface KleeblattMenuState {
  show:boolean;
  show_titel: string;
  show_text: string;
  einstellungenShow:boolean;
  showSubscribeForm:boolean;
  username:string;
  password:string;
}

class KleeblattMenu extends React.Component <KleeblattMenuProps, KleeblattMenuState> {
  public scrollRef:any; // xxx alles verpublichen!

  static scrollPosX:number;
  static scrollStartX:number;
  static scrollEndX:number;

  static scrollTime:number;
  constructor(props:KleeblattMenuProps) {
    super(props);



    this.state = {
      show:false,
      username:"",
      password:"",
      einstellungenShow:false,
      show_text:"",
      show_titel:"",
      showSubscribeForm:false
    };

    this.toggleMenu=this.toggleMenu.bind(this);
    this.closeMenu=this.closeMenu.bind(this);
    this.openMenu=this.openMenu.bind(this);
    this.actionMenu=this.actionMenu.bind(this);
    this.animMenu=this.animMenu.bind(this);
    this.scrollMenu=this.scrollMenu.bind(this);
    this.einstellungen=this.einstellungen.bind(this);
    this.showHTMLText=this.showHTMLText.bind(this);
    this.closeHTMLText=this.closeHTMLText.bind(this);
    this.buildMenu=this.buildMenu.bind(this);
    this.startAnimMaster = this.startAnimMaster.bind(this);
    this.startAnimDetail = this.startAnimDetail.bind(this);
  }

  componentDidMount() {
  }

  static lerp (start:number, end:number, t:number){
    t = (--t)*t*t*t*t+1;
    if( t > 1 ) t = 1;
    if( t < 0 ) t = 0;
    return  (1-t)*start+t*end;
  }

  static epsilon(a:number,b:number){
    return Math.abs(a - b )<=1;
  }
  animMenu(){
    if( this.scrollRef ) {
      if(!KleeblattMenu.epsilon(KleeblattMenu.scrollPosX , KleeblattMenu.scrollEndX ) ) {
        KleeblattMenu.scrollPosX = KleeblattMenu.lerp(KleeblattMenu.scrollStartX, KleeblattMenu.scrollEndX,(Date.now()-KleeblattMenu.scrollTime)/animSpeed);
        this.scrollRef.style.right = KleeblattMenu.scrollPosX + "px";
      }

      let xx = Math.abs(KleeblattMenu.scrollPosX - KleeblattMenu.scrollEndX );
      let d = Math.sqrt(xx*xx);
      if( Math.abs(xx) > 1000){
        return;
      }
      if( !KleeblattMenu.epsilon(d , 1) ) {
        window.requestAnimationFrame( this.animMenu );
      }
      else {
        this.scrollRef.style.right = KleeblattMenu.scrollEndX+"px";
        this.scrollRef = undefined;
        this.setState({show:(KleeblattMenu.scrollEndX===0)});
      }
    }
  }

  scrollMenu() {
    if( !this.scrollRef ) {
      this.scrollRef = document.getElementById("kleeblatt_menu");

      if( !this.state.show) { // einblenden
        this.setState({show:true});
        if (window.matchMedia("(orientation: portrait)").matches) {
          KleeblattMenu.scrollStartX = -window.innerWidth;
        }
        if (window.matchMedia("(orientation: landscape)").matches) {
          KleeblattMenu.scrollStartX = -window.innerWidth/2;
        }

        KleeblattMenu.scrollEndX = 0;
      }
      else { // ausblenden
        KleeblattMenu.scrollStartX = 0;
        if (window.matchMedia("(orientation: portrait)").matches) {
          KleeblattMenu.scrollEndX = -window.innerWidth;
        }
        if (window.matchMedia("(orientation: landscape)").matches) {
          KleeblattMenu.scrollEndX = -window.innerWidth/2;
        }
      }
      KleeblattMenu.scrollPosX = KleeblattMenu.scrollStartX;
      KleeblattMenu.scrollTime = Date.now();

      window.requestAnimationFrame(this.animMenu);
    }
  }

  toggleMenu(){
    this.scrollMenu();
  }
  openMenu(){
    if( !this.state.show )
      this.scrollMenu();
  }
  closeMenu(){
    if( this.state.show )
      this.scrollMenu();
  }
  einstellungen() {
    this.setState({einstellungenShow:true});
  }

  actionMenu(menu_item:any,history:boolean){
    if( menu_item.id_tag_call ){
      menu_item = this.props.kleeblatt.state.id_tags[menu_item.id_tag_call];
    }
    if( menu_item.action_command==="about" ){
      this.props.kleeblatt.scrollKleeblatSouth(true);
      this.closeMenu();
    }
    else
    if( menu_item.action_command==="show_html_text" ){
      if( history ) {
        let contents=findNode(menu_item,"contents");
        if( contents){
          window.history.pushState({action_command: menu_item.action_command,
                                         action_command_parameter: menu_item.action_command_parameter},
                                      menu_item.text,
                                  menu_item.action_command_parameter);
          this.showHTMLText(menu_item.text,contents.text);
        }
      }
    }
    else
    if( menu_item.action_command==='newsletter' ){
      this.setState({showSubscribeForm:true});
    }
    else
    if( menu_item.action_command==='personenliste' ){
      let scrollRef:any = document.getElementById(this.props.kleeblatt.state.master.props.id+"_master");
      scrollRef.style.top = this.props.kleeblatt.props.intro.state.vh+"px";
      this.closeMenu();
      this.props.kleeblatt.state.masterRef.current.setState({masternode:menu_item},this.startAnimMaster);
    }
    else
    if( menu_item.action_command==='person' ){
      let scrollRef:any = document.getElementById(this.props.kleeblatt.state.detail.props.id+"_detail");
      scrollRef.style.top = this.props.kleeblatt.props.intro.state.vh+"px";
      this.closeMenu();
      this.props.kleeblatt.state.detailRef.current.setState({detailnode:menu_item},this.startAnimDetail);
    }
    else
    if( menu_item.action_command === 'target_blank') {
      window.open(menu_item.action_href,"_blank");
    }
    else
    if( menu_item.action_command === 'target_self') {
      window.open(menu_item.action_href,"_self");
    }
    else
    if( menu_item.action_command )
      this.closeMenu();
  }
  startAnimMaster() {
    this.props.kleeblatt.state.detailRef.current.closeKleeblattDetail();
    this.props.kleeblatt.state.masterRef.current.scrollKleeblattMaster();
  }
  startAnimDetail() {
    this.props.kleeblatt.state.detailRef.current.scrollKleeblattDetail();
  }
  closeHTMLText() {
    window.history.pushState({action_command: "", action_command_parameter: ""},"","/");
    this.showHTMLText("","");
  }
  buildMenu(menu_item:any, type:number){
    let cls = "kleeblatt_menu_item";
    if(!menu_item.action_command )
      cls = "kleeblatt_menu_item_no_action";

    if( type === MENU_SUB_ITEM)
      if(menu_item.action_command )
        cls = "kleeblatt_menu_sub_item";
      else
        cls = "kleeblatt_menu_sub_item_no_action";

    if( type === MENU_ITEM_SMALL)
      if(menu_item.action_command )
        cls = "kleeblatt_menu_item_small";
      else
        cls = "kleeblatt_menu_item_small_no_action";

    if( type === MENU_TEXT)
      if(menu_item.action_command )
        cls = "kleeblatt_menu_text";
      else
        cls = "kleeblatt_menu_text_no_action";

    return (<div
              className={cls}
              key={menu_item.id+menu_item.action_command}
            onClick={(e)=>this.actionMenu(menu_item,true)}
            dangerouslySetInnerHTML={{__html: menu_item.text}}
          ></div>);
  }
  changed(e:any,f:string) {
    switch(f){
      case "username":
        this.setState({username:e.currentTarget.value});
        break;
      case "password":
        this.setState({password:e.currentTarget.value});
    }
  }
  showHTMLText(titel:string, text:string) {
    this.setState({show_titel:titel,show_text:text});
  }

  collectMenu(z:any,m:any) {
    for(let n in m.children){
      let node = m.children[n];
      switch(node.type) {
        case 'menu_item':
        case 'master_menu_item':
          z.push(this.buildMenu(node,MENU_ITEM));
          break;
        case 'menu_sub_item':
          z.push(this.buildMenu(node,MENU_SUB_ITEM));
          break;
        case 'menu_item_small':
          z.push(this.buildMenu(node,MENU_ITEM_SMALL));
          break;
        case 'menu_item_gap':
          z.push((<br/>));
          break;
        case 'menu_text':
          z.push(this.buildMenu(node,MENU_TEXT));
          break;
      }
      this.collectMenu(z,node);
    }
  }
  render() {
    if( !this.props.data ) return;
    let z:any=[];

    this.collectMenu(z,this.props.data);

    z.push((<><br/><br/><br/></>));

    if( Intro.urlParams) {
      let login = Intro.urlParams.get("edit");

      let access_token = Intro.urlParams.get("access_token");
      if((access_token===null)||((access_token===undefined))){
        if((login!==null)&&((login!==undefined))){
          let form = (
            <>
              <br/>
              <Form >
              {this.props.kleeblatt.istAngemeldet()?
              <>
                <Button variant="outline-danger" onClick={(e)=>this.props.kleeblatt.abmelden()}>Abmelden</Button>
                <br/>
                <Button variant="outline-info" onClick={(e)=>this.einstellungen()}>Einstellungen</Button>
                <KleeblattEinstellungen kleeblattMenu={this}/>
                <br/>
                <br/>
                <br/>
              </>
              :
               <>
                <Form.Control type="text" placeholder="Benutzername" autoComplete={"username"} value={this.state.username} onChange={(e)=>this.changed(e,"username")} />
                <div style={{height:"10px"}}/>
                <Form.Control type="password" placeholder="Passwort" autoComplete={"current-password"} value={this.state.password} onChange={(e)=>this.changed(e,"password")}/>
                <div style={{height:"10px"}}/>
                {this.props.kleeblatt.state.error_object}
                <Button variant="outline-info" onClick={(e)=>this.props.kleeblatt.anmelden(this.state.username,this.state.password)}>Anmelden</Button>
                <br/>
                <br/>
                <br/>
                <br/>
              </>
              }
              </Form>
             </>
          )
          z.push(form);
        }
      }
    }
    return (<>
        <div className="kleeblatt_menu_icon" key={"kleeblatt_menu_icon"} onClick={this.toggleMenu}>Menü {iconHamburger}</div>
        <div className="kleeblatt_menu_backdrop" key={"kleeblatt_menu_backdrop"} style={{display:this.state.show?"block":"none"}} onClick={this.closeMenu}></div>
        <div className="kleeblatt_menu" id="kleeblatt_menu" key={"kleeblatt_menu"} style={{display:this.state.show?"block":"none"}}>
          <div className="kleeblatt_menu_container" key={"kleeblatt_menu_container"} style={{display:this.state.show?"block":"none"}}>
            {z}
          </div>
        </div>

        <Modal show={this.state.show_text!==""}
              onHide={()=>this.closeHTMLText()}
              dialogClassName="modal-fullscreen">
            <Modal.Header>
              <h1>{this.state.show_titel}</h1>
              <span onClick={(e)=>{this.closeHTMLText()}}>{iconTimes}</span>
            </Modal.Header>

            <Modal.Body style={{padding:0}}>
              <div style={{height:"100%", padding:"20px", margin:0,overflowX:"hidden",overflowY:"scroll"}} dangerouslySetInnerHTML={{__html: this.state.show_text}}>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={(e)=>this.closeHTMLText()} size="sm">Schließen</Button>
            </Modal.Footer>
        </Modal>
        <NewsLetter short={false} kleeblattMenu={this} link_name="Newsletter abonnieren"/>
    </>
    );
  }
}

export default KleeblattMenu;