import React from "react";

declare const window: any;

interface IIFrameProps {
    iframe_id:string;
    orientation:string;
    gap:string;
    url:string;
}

interface IIFrameState {
    iframe:any;
    iframeRef:any;
}

class IIFrame extends React.Component <IIFrameProps, IIFrameState> {
    constructor(props:IIFrameProps) {
        super(props);
        this.state = {
            iframe:undefined,
            iframeRef:React.createRef()
        };
    }

    componentDidMount() {
        if( !this.state.iframe ) {
            if( this.state.iframeRef.current ) {
                this.state.iframeRef.current.style.background="#ffffff";
            }
        }
    }

    render() {
        return (
            <iframe key={this.props.iframe_id}
                src={this.props.url}
                id={this.props.iframe_id}
                ref={this.state.iframeRef}
                className={"iframe_"+this.props.orientation+"_"+this.props.gap}/>
        )
    }
}

export default IIFrame;