import React from "react";
import KleeblattMenu from './KleeblattMenu';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {getURL} from "./helpers";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const iconTimes = <FontAwesomeIcon icon={faTimes} />

interface KleeblattEinstellungenProps {
  kleeblattMenu:KleeblattMenu;
}

interface KleeblattEinstellungenState {
}

class KleeblattEinstellungen extends React.Component <KleeblattEinstellungenProps, KleeblattEinstellungenState> {
   constructor(props:KleeblattEinstellungenProps) {
        super(props);

        this.state = {
        };

        this.show=this.show.bind(this);
        this.close=this.close.bind(this);
        this.kleeblattErstellen=this.kleeblattErstellen.bind(this);
        this.kleeblattLoeschen=this.kleeblattLoeschen.bind(this);
    }
    componentDidMount() {
    }

    click(e:any){
      this.show(true);
    }
    show(s:boolean){
      this.props.kleeblattMenu.setState({einstellungenShow:s});
    }
    onChange(e:any){
      switch(e.target.id) {
      }
    }
    onActionChange(e:any) {
      switch(e.target.id) {
      }
    }
    onActionValueChange(e:any) {
    }
    close() {
      this.show(false);
    }
    kleeblattErstellen(orientation:string,subtype:string){
      let url = getURL("saveKleeblatt.php");

      let err=[];
      let app = this;
      fetch(url,{
          method: 'POST',
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({create:true,orientation:orientation,subtype:subtype,access_token:this.props.kleeblattMenu.props.kleeblatt.state.access_token})
        }
      ).then(function(response){
        return response.json();
      })
        .then(function(r) {
          err = [];
          app.props.kleeblattMenu.props.kleeblatt.setData(r);
        })
        .catch((error) => {
          err = [];
          alert(error);
        })
    }
    kleeblattLoeschen(orientation:string){
      let url = getURL("saveKleeblatt.php");
      let err=[];
      let app = this;
      fetch(url,{
          method: 'POST',
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({delete:true,orientation:orientation,access_token:this.props.kleeblattMenu.props.kleeblatt.state.access_token})
        }
      ).then(function(response){
        return response.json();
      })
        .then(function(r) {
          err = [];
          app.props.kleeblattMenu.props.kleeblatt.setData(r);
        })
        .catch((error) => {
          err = [];
          alert(error);
        })
    }

    render() {
        return (
            <>
                <Modal show={this.props.kleeblattMenu.state.einstellungenShow}
                      onHide={()=>this.show(false)}
                      dialogClassName="modal-90w">
                    <Modal.Header>
                      {"Kleeblatt-Einstellungen"}
                      <span onClick={(e)=>{this.show(false)}}>{iconTimes}</span>
                    </Modal.Header>

                    <Modal.Body>
                      <div>
                        {"Mitte"}
                        <Button variant="outline-success" onClick={(e)=>this.kleeblattErstellen("middle","")} size="sm">Dummy-Page</Button>
                        <Button variant="outline-warning" onClick={(e)=>this.kleeblattErstellen("middle","map")} size="sm">Dummy-Map</Button>
                        </div>

                        <div>
                          {"Norden"}
                          <Button variant="outline-success" onClick={(e)=>this.kleeblattErstellen("north","")} size="sm">Dummy-Page</Button>
                          <Button variant="outline-warning" onClick={(e)=>this.kleeblattErstellen("north","map")} size="sm">Dummy-Map</Button>
                          <Button variant="outline-danger" onClick={(e)=>this.kleeblattLoeschen("north")} size="sm">Löschen</Button>
                        </div>

                        <div>
                          {"Osten"}
                          <Button variant="outline-success" onClick={(e)=>this.kleeblattErstellen("east","")} size="sm">Dummy-Page</Button>
                          <Button variant="outline-warning" onClick={(e)=>this.kleeblattErstellen("east","map")} size="sm">Dummy-Map</Button>
                          <Button variant="outline-danger" onClick={(e)=>this.kleeblattLoeschen("east")} size="sm">Löschen</Button>
                        </div>

                        <div>
                          {"South"}
                          <Button variant="outline-success" onClick={(e)=>this.kleeblattErstellen("south","")} size="sm">Dummy-Page</Button>
                          <Button variant="outline-warning" onClick={(e)=>this.kleeblattErstellen("south","map")} size="sm">Dummy-Map</Button>
                          <Button variant="outline-danger" onClick={(e)=>this.kleeblattLoeschen("south")} size="sm">Löschen</Button>
                        </div>
                        <div>
                          {"West"}
                          <Button variant="outline-success" onClick={(e)=>this.kleeblattErstellen("west","")} size="sm">Dummy-Page</Button>
                          <Button variant="outline-warning" onClick={(e)=>this.kleeblattErstellen("west","map")} size="sm">Dummy-Map</Button>
                          <Button variant="outline-danger" onClick={(e)=>this.kleeblattLoeschen("west")} size="sm">Löschen</Button>
                        </div>

                      <Button variant="outline-secondary" onClick={this.close} size="sm">Abbrechen</Button>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default KleeblattEinstellungen;