import React, {createRef} from "react";

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getURL, px} from "./helpers";
import Intro from './Intro';
import Map from './Map';
import Video from './Video';
import IIFrame from './IIFrame';
import EditablePage from './EditablePage';
import KleeblattMenu from './KleeblattMenu';
import PageEditor from './pageedtor/PageEditor';

import sha256 from './sha256';
import Master from "./Master";

import {faAdd, faEdit, faEnvelope, faPhone, faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import Detail from "./Detail";

//const iconRemove = <FontAwesomeIcon icon={faRemove} />
const iconEdit = <FontAwesomeIcon icon={faEdit} />
const iconAdd = <FontAwesomeIcon icon={faAdd} />
const iconEnvelope = <FontAwesomeIcon icon={faEnvelope} />
const iconPhone = <FontAwesomeIcon icon={faPhone} />
const iconPrev = <FontAwesomeIcon icon={faChevronLeft} />
const iconNext = <FontAwesomeIcon icon={faChevronRight} />

const needsGoogleMaps = false;

declare const window: any;

interface KleeBlattProps {
    id:string;
    leafs:string; // NESW
    intro:Intro;
}

interface KleeBlattState {
    visible:boolean;
    left:number,
    top:number,

    access_token:string;
    role:string;
    error_object:any;

    middle:any;
    north:any;
    northUp:number;
    east:any;
    eastUp:number;
    southUp:number;
    south:any;
    west:any;
    westUp:number;
    menu:any;
    master:Master;
    masterRef:any;
    detail:Detail;
    detailRef:any;
    id_tags:any;
}

const animSpeed = 350*2;

class KleeBlatt extends React.Component <KleeBlattProps, KleeBlattState> {
    public menuRef:any = createRef();
    static scrollRef:any;
    static scrollPosX:number;
    static scrollPosY:number;
    static scrollStartX:number;
    static scrollStartY:number;
    static scrollEndX:number;
    static scrollEndY:number;
    static scrollEndStyleX:string;
    static scrollEndStyleY:string;
    static scrollTime:number;

    static north_div:any;
    static east_div:any;
    static south_div:any;
    static west_div:any;

    static northpatch_div:any;
    static app_div:any;
    static open_position:any;
    static openstarttime = 0;
    static westfalen_baut_kultur_open=false;
    static northgap = 50;
    static eastgap = 50;
    static southgap = 50;
    static westgap = 50;

    constructor(props:KleeBlattProps) {
        super(props);
        let masterRef:any=React.createRef();
        let master:any = (<Master kleeblatt={this} intro={this.props.intro} ref={masterRef} id={"master"}/>);

        let detailRef:any=React.createRef();
        let detail:any = (<Detail kleeblatt={this} intro={this.props.intro} ref={detailRef} id={"detail"}/>);

        this.state = {
            visible:true,
            left:0,
            top:0,
            access_token:"",
            role:"",
            error_object:[],

            middle:undefined,
            north:undefined,
            northUp:0,
            east:undefined,
            eastUp:0,
            south:undefined,
            southUp:0,
            west:undefined,
            westUp:0,
            menu:undefined,
            master:master,
            masterRef:masterRef,
            detail:detail,
            detailRef:detailRef,
            id_tags:[]
        }
        this.anmelden = this.anmelden.bind(this);
        this.abmelden = this.abmelden.bind(this);
        this.setData = this.setData.bind(this);
        this.openApp = this.openApp.bind(this);
        this.openApp = this.openApp.bind(this);
        this.closeApp = this.closeApp.bind(this);
        this.findActionCommandParameter=this.findActionCommandParameter.bind(this);
        this._findActionCommandParameter=this._findActionCommandParameter.bind(this);
        this.showUrlPage=this.showUrlPage.bind(this);

        this.animKleeblattNorth=this.animKleeblattNorth.bind(this);
        this.scrollKleeblatNorth=this.scrollKleeblatNorth.bind(this);
        this.animKleeblattEast=this.animKleeblattEast.bind(this);
        this.scrollKleeblatEast=this.scrollKleeblatEast.bind(this);
        this.animKleeblattSouth=this.animKleeblattSouth.bind(this);
        this.scrollKleeblatSouth=this.scrollKleeblatSouth.bind(this);
        this.animKleeblattWest=this.animKleeblattWest.bind(this);
        this.scrollKleeblatWest=this.scrollKleeblatWest.bind(this);
        this.animKleeblattMiddle=this.animKleeblattMiddle.bind(this);
        this.scrollKleeblatMiddle=this.scrollKleeblatMiddle.bind(this);

        this.loadAll = this.loadAll.bind(this);
        this.waitForGoogleMaps = this.waitForGoogleMaps.bind(this);
        this.showPerson = this.showPerson.bind(this);
    }
    componentDidMount(){
        if( !needsGoogleMaps ) {
            this.loadAll(); // erst laden wenn google maps geladen sind
            return;
        }
        if(typeof google != 'undefined') {
            this.loadAll(); // erst laden wenn google maps geladen sind
            return;
        }
        this.waitForGoogleMaps();
    }
    waitForGoogleMaps() {
        if(typeof google != 'undefined') {
            this.loadAll(); // erst laden wenn google maps geladen sind
            return;
        }
        setTimeout(this.waitForGoogleMaps,100);
    }
    loadAll() {
        let app = this;
        let url = getURL("all.php?cb=1");

        fetch(url,{
              headers : {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }
          }
        )
          .then(function(response){
              return response.json();
          })
          .then(function(r) {
              app.setData(r);
          });
    }

    static lerp (start:number, end:number, t:number){
        t = (--t)*t*t*t*t+1;
        if( t > 1 ) t = 1;
        if( t < 0 ) t = 0;
        return  (1-t)*start+t*end;
    }
    static epsilon(a:number,b:number){
        return Math.abs(a - b )<=1;
    }

    endAnim() {
        KleeBlatt.scrollRef = undefined;
    }

    stopAllMedia(l:any) {
        if( !l ) return;
        try {
            for(let f in l.iframes ) {
                let fr = l.iframes[f];
                if( fr ) {
                    if( fr.props ) {
                        let el = window.document.getElementById(fr.props.iframe_id);
                        el.contentWindow.postMessage("stopAllMedia_52e334b0563155aa8eab496cb1d2a7b5","*");
                    }
                }
            }
        }
        catch(e) {}
    }

    animKleeblattNorth(){
        if( KleeBlatt.scrollRef ) {
            if(!KleeBlatt.epsilon(KleeBlatt.scrollPosX, KleeBlatt.scrollEndX) ) {
                KleeBlatt.scrollPosX = KleeBlatt.lerp(KleeBlatt.scrollStartX, KleeBlatt.scrollEndX,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.left = px(KleeBlatt.scrollPosX);
            }

            if( !KleeBlatt.epsilon(KleeBlatt.scrollPosY, KleeBlatt.scrollEndY) ) {
                KleeBlatt.scrollPosY = KleeBlatt.lerp(KleeBlatt.scrollStartY, KleeBlatt.scrollEndY,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.top = px(KleeBlatt.scrollPosY);
            }

            let xx = Math.abs(KleeBlatt.scrollPosX - KleeBlatt.scrollEndX );
            let yy = Math.abs(KleeBlatt.scrollPosY - KleeBlatt.scrollEndY );
            let d = Math.sqrt(xx*xx+yy*yy);

            if( !KleeBlatt.epsilon(d , 1) ) {
                window.requestAnimationFrame( this.animKleeblattNorth );
            }
            else {
                KleeBlatt.scrollRef.style.left = KleeBlatt.scrollEndStyleX;
                KleeBlatt.scrollRef.style.top = KleeBlatt.scrollEndStyleY;
                this.setState({northUp:this.state.northUp?0:1,
                                southUp:0,
                                eastUp:0,
                                westUp:0}, this.endAnim );
            }
        }
    }

    scrollKleeblatNorth(open:any) {
        if( !KleeBlatt.scrollRef ) {
            KleeBlatt.scrollRef = document.getElementById("kleeblatt");

            KleeBlatt.scrollPosX = KleeBlatt.scrollRef.clientLeft;
            KleeBlatt.scrollPosY = KleeBlatt.scrollRef.clientTop;

            let r = KleeBlatt.scrollRef.getBoundingClientRect();

            KleeBlatt.scrollStartX = r.left;
            KleeBlatt.scrollEndX = -this.props.intro.state.vw;
            KleeBlatt.scrollEndStyleX = px(-this.props.intro.state.vw);

            if( !this.state.northUp || open === true ) { // auklappen
                KleeBlatt.scrollStartY = r.top;
                KleeBlatt.scrollEndY = -( 50 + KleeBlatt.northgap);
                KleeBlatt.scrollEndStyleY = px( -50 - KleeBlatt.northgap );
                this.setState({northUp:0});
            }
            else if(open===undefined || open === false)  { // zuklappen
                KleeBlatt.scrollStartY = r.top;
                KleeBlatt.scrollEndY = -this.props.intro.state.vh;
                KleeBlatt.scrollEndStyleY = px( -this.props.intro.state.vh );
                this.stopAllMedia(this.state.north);
            }

            KleeBlatt.scrollTime = Date.now();
            window.requestAnimationFrame(this.animKleeblattNorth);
        }
    }

    animKleeblattSouth(){
        if( KleeBlatt.scrollRef ) {
            if(!KleeBlatt.epsilon(KleeBlatt.scrollPosX, KleeBlatt.scrollEndX )) {
                KleeBlatt.scrollPosX = KleeBlatt.lerp(KleeBlatt.scrollStartX, KleeBlatt.scrollEndX,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.left = KleeBlatt.scrollPosX + "px";
            }

            if( !KleeBlatt.epsilon(KleeBlatt.scrollPosY, KleeBlatt.scrollEndY )) {
                KleeBlatt.scrollPosY = KleeBlatt.lerp(KleeBlatt.scrollStartY, KleeBlatt.scrollEndY,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.top = KleeBlatt.scrollPosY + "px";
            }

            let xx = Math.abs(KleeBlatt.scrollPosX - KleeBlatt.scrollEndX );
            let yy = Math.abs(KleeBlatt.scrollPosY - KleeBlatt.scrollEndY );
            let d = Math.sqrt(xx*xx+yy*yy);

            if( !KleeBlatt.epsilon(d, 1) ) {
                window.requestAnimationFrame( this.animKleeblattSouth );
            }
            else {
                KleeBlatt.scrollRef.style.left = KleeBlatt.scrollEndStyleX;
                KleeBlatt.scrollRef.style.top = KleeBlatt.scrollEndStyleY;
                debugger;
                this.setState({
                    northUp:0,
                    southUp:this.state.southUp?0:1,
                    eastUp:0,
                    westUp:0}, this.endAnim );
            }
        }
    }

    scrollKleeblatSouth(open:any) {
        if( !KleeBlatt.scrollRef ) {
            KleeBlatt.scrollRef = document.getElementById("kleeblatt");

            KleeBlatt.scrollPosX = KleeBlatt.scrollRef.clientLeft;
            KleeBlatt.scrollPosY = KleeBlatt.scrollRef.clientTop;

            let r = KleeBlatt.scrollRef.getBoundingClientRect();

            KleeBlatt.scrollStartX = r.left;
            KleeBlatt.scrollEndX = -this.props.intro.state.vw;
            KleeBlatt.scrollEndStyleX = px(-this.props.intro.state.vw);

            //KleeBlatt.epsilon(r.top, -this.props.intro.state.vh ) && (open===undefined || open === true)
            if( !this.state.southUp || open === true ) { // auklappen
                KleeBlatt.scrollStartY = r.top;
                KleeBlatt.scrollEndY = -2 * this.props.intro.state.vh + 50 + KleeBlatt.southgap;
                KleeBlatt.scrollEndStyleY = px(-2*this.props.intro.state.vh+50+KleeBlatt.southgap);
                this.setState({southUp:0});
            }
            else if(open===undefined || open === false)  { // zuklappen
                KleeBlatt.scrollStartY = r.top;
                KleeBlatt.scrollEndY = -this.props.intro.state.vh;
                KleeBlatt.scrollEndStyleY = px(-this.props.intro.state.vh);
                this.stopAllMedia(this.state.south);
            }

            KleeBlatt.scrollTime = Date.now();
            window.requestAnimationFrame(this.animKleeblattSouth);
        }
    }

    animKleeblattEast(){
        if( KleeBlatt.scrollRef ) {
            if(!KleeBlatt.epsilon(KleeBlatt.scrollPosX , KleeBlatt.scrollEndX ) ) {
                KleeBlatt.scrollPosX = KleeBlatt.lerp(KleeBlatt.scrollStartX, KleeBlatt.scrollEndX,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.left = KleeBlatt.scrollPosX + "px";
            }

            if( !KleeBlatt.epsilon(KleeBlatt.scrollPosY, KleeBlatt.scrollEndY ) ) {
                KleeBlatt.scrollPosY = KleeBlatt.lerp(KleeBlatt.scrollStartY, KleeBlatt.scrollEndY,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.top = KleeBlatt.scrollPosY + "px";
            }

            let xx = Math.abs(KleeBlatt.scrollPosX - KleeBlatt.scrollEndX );
            let yy = Math.abs(KleeBlatt.scrollPosY - KleeBlatt.scrollEndY );
            let d = Math.sqrt(xx*xx+yy*yy);

            if( !KleeBlatt.epsilon(d , 1) ) {
                window.requestAnimationFrame( this.animKleeblattEast );
            }
            else {
                KleeBlatt.scrollRef.style.left = KleeBlatt.scrollEndStyleX;
                KleeBlatt.scrollRef.style.top = KleeBlatt.scrollEndStyleY;
                this.setState({
                    northUp:0,
                    southUp:0,
                    eastUp:this.state.eastUp?0:1,
                    westUp:0
                }, this.endAnim );
            }
        }
    }

    scrollKleeblatEast(open:any) {
        if( !KleeBlatt.scrollRef ) {
            KleeBlatt.scrollRef = document.getElementById("kleeblatt");

            KleeBlatt.scrollPosX = KleeBlatt.scrollRef.clientLeft;
            KleeBlatt.scrollPosY = KleeBlatt.scrollRef.clientTop;

            let r = KleeBlatt.scrollRef.getBoundingClientRect();
            if( !this.state.eastUp || open === true ) { // auklappen{
                KleeBlatt.scrollStartX = r.left;
                KleeBlatt.scrollEndX = -2 * this.props.intro.state.vw + 50 + KleeBlatt.eastgap;
                KleeBlatt.scrollEndStyleX = px(-2*this.props.intro.state.vw+50+KleeBlatt.eastgap);
                this.setState({eastUp:0});
            }
            else if(open===undefined || open === false)  { // zuklappen
                KleeBlatt.scrollStartX = r.left;
                KleeBlatt.scrollEndX = -this.props.intro.state.vw;
                KleeBlatt.scrollEndStyleX = px(-this.props.intro.state.vw);
                this.stopAllMedia(this.state.east);
            }

            KleeBlatt.scrollStartY = r.top;
            KleeBlatt.scrollEndY = -this.props.intro.state.vh;
            KleeBlatt.scrollEndStyleY = px(-this.props.intro.state.vh);

            KleeBlatt.scrollTime = Date.now();
            window.requestAnimationFrame(this.animKleeblattEast);
        }
    }

    animKleeblattWest(){
        if( KleeBlatt.scrollRef ) {
            if(!KleeBlatt.epsilon(KleeBlatt.scrollPosX , KleeBlatt.scrollEndX )) {
                KleeBlatt.scrollPosX = KleeBlatt.lerp(KleeBlatt.scrollStartX, KleeBlatt.scrollEndX,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.left = px(KleeBlatt.scrollPosX);
            }

            if( !KleeBlatt.epsilon(KleeBlatt.scrollPosY, KleeBlatt.scrollEndY )) {
                KleeBlatt.scrollPosY = KleeBlatt.lerp(KleeBlatt.scrollStartY, KleeBlatt.scrollEndY,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.top = px(KleeBlatt.scrollPosY);
            }

            let xx = Math.abs(KleeBlatt.scrollPosX - KleeBlatt.scrollEndX );
            let yy = Math.abs(KleeBlatt.scrollPosY - KleeBlatt.scrollEndY );
            let d = Math.sqrt(xx*xx+yy*yy);

            if( !KleeBlatt.epsilon(d , 1) ) {
                window.requestAnimationFrame( this.animKleeblattWest );
            }
            else {
                KleeBlatt.scrollRef.style.left = KleeBlatt.scrollEndStyleX;
                KleeBlatt.scrollRef.style.top = KleeBlatt.scrollEndStyleY;
                this.setState({
                            northUp:0,
                            southUp:0,
                            eastUp:0,
                            westUp:this.state.westUp?0:1
                        }, this.endAnim );
            }
        }
    }

    scrollKleeblatWest(open:any) {
        if( !KleeBlatt.scrollRef ) {
            KleeBlatt.scrollRef = document.getElementById("kleeblatt");

            KleeBlatt.scrollPosX = KleeBlatt.scrollRef.clientLeft;
            KleeBlatt.scrollPosY = KleeBlatt.scrollRef.clientTop;

            let r = KleeBlatt.scrollRef.getBoundingClientRect();
            if( !this.state.westUp || open === true ) { // auklappen{
                KleeBlatt.scrollStartX = r.left;
                KleeBlatt.scrollEndX = -50 - KleeBlatt.westgap;
                KleeBlatt.scrollEndStyleX = px( -50 - KleeBlatt.westgap );
                this.setState({westUp:0});
            }
            else if(open===undefined || open === false)  {
                KleeBlatt.scrollStartX = r.left;
                KleeBlatt.scrollEndX = -this.props.intro.state.vw;
                KleeBlatt.scrollEndStyleX = px(-this.props.intro.state.vw);
                this.stopAllMedia(this.state.west);
            }

            KleeBlatt.scrollStartY = r.top;
            KleeBlatt.scrollEndY = -this.props.intro.state.vh;
            KleeBlatt.scrollEndStyleY = px(-this.props.intro.state.vh);

            KleeBlatt.scrollTime = Date.now();
            window.requestAnimationFrame(this.animKleeblattWest);
        }
    }

    animKleeblattMiddle(){
        if( KleeBlatt.scrollRef ) {
            if(!KleeBlatt.epsilon(KleeBlatt.scrollPosX, KleeBlatt.scrollEndX )) {
                KleeBlatt.scrollPosX = KleeBlatt.lerp(KleeBlatt.scrollStartX, KleeBlatt.scrollEndX,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.left = px(KleeBlatt.scrollPosX);
            }

            if( !KleeBlatt.epsilon(KleeBlatt.scrollPosY, KleeBlatt.scrollEndY )) {
                KleeBlatt.scrollPosY = KleeBlatt.lerp(KleeBlatt.scrollStartY, KleeBlatt.scrollEndY,(Date.now()-KleeBlatt.scrollTime)/animSpeed);
                KleeBlatt.scrollRef.style.top = px(KleeBlatt.scrollPosY);
            }

            let xx = Math.abs(KleeBlatt.scrollPosX - KleeBlatt.scrollEndX );
            let yy = Math.abs(KleeBlatt.scrollPosY - KleeBlatt.scrollEndY );
            let d = Math.sqrt(xx*xx+yy*yy);

            if( !KleeBlatt.epsilon(d , 1) ) {
                window.requestAnimationFrame( this.animKleeblattMiddle );
            }
            else {
                KleeBlatt.scrollRef.style.left = KleeBlatt.scrollEndStyleX;
                KleeBlatt.scrollRef.style.top = KleeBlatt.scrollEndStyleY;
                KleeBlatt.scrollRef = undefined;
            }
        }
    }

    scrollKleeblatMiddle() {
        if( !KleeBlatt.scrollRef ) {
            KleeBlatt.scrollRef = document.getElementById("kleeblatt");

            KleeBlatt.scrollPosX = KleeBlatt.scrollRef.clientLeft;
            KleeBlatt.scrollPosY = KleeBlatt.scrollRef.clientTop;

            let r = KleeBlatt.scrollRef.getBoundingClientRect();

            KleeBlatt.scrollStartX = r.left;
            KleeBlatt.scrollEndX = -this.props.intro.state.vw;
            KleeBlatt.scrollEndStyleX = px(-this.props.intro.state.vw);

            KleeBlatt.scrollStartY = r.top;
            KleeBlatt.scrollEndY = -this.props.intro.state.vh;
            KleeBlatt.scrollEndStyleY = px(-this.props.intro.state.vh);

            KleeBlatt.scrollTime = Date.now();
            window.requestAnimationFrame(this.animKleeblattMiddle);
        }
    }

    click(e:any,type:string){
        e.stopPropagation();
        switch(type) {
            case 'north':
                this.scrollKleeblatNorth(undefined);
                if( this.menuRef.current) {
                    this.menuRef.current.closeMenu();
                }
                break;
            case 'east':
                this.scrollKleeblatEast(undefined);
                if( this.menuRef.current) {
                    this.menuRef.current.closeMenu();
                }
                break;
            case 'south':
                this.scrollKleeblatSouth(undefined);
                if( this.menuRef.current) {
                    this.menuRef.current.closeMenu();
                }
                break;
            case 'west':
                this.scrollKleeblatWest(undefined);
                if( this.menuRef.current) {
                    this.menuRef.current.closeMenu();
                }
                break;
            case 'middle':
                this.scrollKleeblatMiddle();
                if( this.menuRef.current) {
                    //this.menuRef.current.closeMenu();
                }
                break;
        }
    }

    open(e:any,type:string){
        e.stopPropagation();
        switch(type) {
            case 'north':
                this.scrollKleeblatNorth(true);
                if( this.menuRef.current) {
                    this.menuRef.current.closeMenu();
                }
                break;
            case 'east':
                this.scrollKleeblatEast(true);
                if( this.menuRef.current) {
                    this.menuRef.current.closeMenu();
                }
                break;
            case 'south':
                this.scrollKleeblatSouth(true);
                if( this.menuRef.current) {
                    this.menuRef.current.closeMenu();
                }
                break;
            case 'west':
                this.scrollKleeblatWest(true);
                if( this.menuRef.current) {
                    this.menuRef.current.closeMenu();
                }
                break;
            case 'middle':
                this.scrollKleeblatMiddle();
                if( this.menuRef.current) {
                    //this.menuRef.current.closeMenu();
                }
                break;
        }
    }

    openApp() {
        if( KleeBlatt.open_position<50 ) {
            KleeBlatt.open_position = KleeBlatt.lerp(0,50,((Date.now())-KleeBlatt.openstarttime)/animSpeed);
            if( KleeBlatt.open_position < 50 ){
                KleeBlatt.north_div.style.top=px(50-KleeBlatt.open_position);
                KleeBlatt.northpatch_div.style.top=px(50-50-KleeBlatt.open_position);
                KleeBlatt.east_div.style.left = px( this.props.intro.state.vw + this.props.intro.state.vw - (50-KleeBlatt.open_position) );
                KleeBlatt.south_div.style.top = px( this.props.intro.state.vh + this.props.intro.state.vh - (50-KleeBlatt.open_position) );

                KleeBlatt.west_div.style.left = px(50-KleeBlatt.open_position);
                KleeBlatt.app_div.style.opacity = KleeBlatt.open_position/50;
                window.requestAnimationFrame(this.openApp);
            }
            else
            {
                KleeBlatt.north_div.style.top=px(0);
                KleeBlatt.northpatch_div.style.top=px(-50);
                KleeBlatt.east_div.style.left=px(this.props.intro.state.vw+this.props.intro.state.vw);
                KleeBlatt.south_div.style.top=px(this.props.intro.state.vh+this.props.intro.state.vh);
                KleeBlatt.west_div.style.left=px(-50);
                KleeBlatt.app_div.style.opacity=1;
                KleeBlatt.openstarttime=0;
                KleeBlatt.westfalen_baut_kultur_open = true;
            }
        }
    }

    closeApp() {
        if( KleeBlatt.open_position<50 ) {
            KleeBlatt.open_position = KleeBlatt.lerp(0,50,((Date.now())-KleeBlatt.openstarttime)/animSpeed);
            if( KleeBlatt.open_position < 50 ){
                KleeBlatt.north_div.style.top = px(KleeBlatt.open_position);
                KleeBlatt.northpatch_div.style.top = px(-50+KleeBlatt.open_position);
                KleeBlatt.east_div.style.left = px( this.props.intro.state.vw+ this.props.intro.state.vw - KleeBlatt.open_position);
                KleeBlatt.south_div.style.top = px( this.props.intro.state.vh + this.props.intro.state.vh-KleeBlatt.open_position);
                KleeBlatt.west_div.style.left = px(KleeBlatt.open_position);
                KleeBlatt.app_div.style.opacity = (1-(KleeBlatt.open_position/50));
                window.requestAnimationFrame(this.closeApp);
            }
            else
            {
                KleeBlatt.north_div.style.top = px(50);
                KleeBlatt.northpatch_div.style.top = px(0);
                KleeBlatt.east_div.style.left = px( this.props.intro.state.vw+this.props.intro.state.vw-50);
                KleeBlatt.south_div.style.top = px( this.props.intro.state.vh+this.props.intro.state.vh-50);
                KleeBlatt.west_div.style.left = px(50);

                KleeBlatt.openstarttime = 0;
                KleeBlatt.westfalen_baut_kultur_open = false;
                KleeBlatt.app_div.style.opacity=0;
                KleeBlatt.app_div.style.display ="none";
            }
        }
    }

    prevent(e:any,key:string) {
        e.preventDefault();
        e.stopPropagation();
    }

    anmelden(username:string, password:string) {
        let url = getURL("getAccessToken.php");


        let err=[];
        if( !username )
            err.push((<span className="error_text">Bitte gib deinern Benutzernamen an!</span>));
        if( !password ) {
            if( err.length>0 )
                err.push((<br/>));
            err.push((<span className="error_text">Bitte gib dein Passwort an!</span>));
            err.push((<br/>));
        }
        if( err.length>0 ) {
            this.setState({error_object:err,access_token:"",role:""});
            return;
        }
        this.setState({error_object:err, access_token:"",role:""});
        let t = this;
        fetch(url,{
              method: 'POST',
              headers : {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              },
              body: JSON.stringify({username:username,password:sha256(password)})
          }
        ).then(function(response){
            return response.json();
        })
          .then(function(r) {
              err = [];
              if( r.access_token && r.role ) {
                  err.push((<span className="success_text">Anmeldung erfolgt!</span>));
                  if(r.role==="supervisor") {
                      t.setState({error_object:err, access_token:r.access_token,role:r.role});
                      if( t.menuRef.current) {
                          t.menuRef.current.closeMenu();
                      }
                  }
              }
              else {
                  err.push((<span className="danger_text">Ungültiger User!</span>));
                  t.setState({error_object:err, access_token:"",role:""});
              }
          })
          .catch((error) => {
              err = [];
              err.push(<span className="error_text">Fehler beim Abrufen des Access-Tokens!</span>);

              t.setState({error_object:err, access_token:"", role:""});
          })
    }
    abmelden() {
        this.setState({error_object:[], access_token:"", role:""});
        if( this.menuRef.current) {
            this.menuRef.current.closeMenu();
        }
    }
    istAngemeldet() {
        return this.state.access_token!=="";
    }
    darfEditieren() {
        return this.state.role==="supervisor";
    }
    finIDTags(node:any, id_tags:any) {
        if( node.id_tag) {
            id_tags[node.id_tag] = node;
        }
        for(let c in node.children){
            this.finIDTags(node.children[c],id_tags);
        }
    }
    setData(d:any) {
        let menu:any;
        let middle:any;
        let north:any;
        let east:any;
        let south:any;
        let west:any;
        let id_tags:any = [];

        this.finIDTags(d,id_tags);
        for(let c in d.children){
            if( d.children[c].type==="menu" )
                menu=d.children[c];
            if( d.children[c].type==="middle" )
                middle=d.children[c];
            if( d.children[c].type==="north" )
                north=d.children[c];
            if( d.children[c].type==="east" )
                east=d.children[c];
            if( d.children[c].type==="south" )
                south=d.children[c];
            if( d.children[c].type==="west" )
                west=d.children[c];
        }
        this.setState({menu:menu,
                        middle:middle,
                        north:north,
                        east:east,
                        south:south,
                        west:west,
                        id_tags:id_tags},
          this.showUrlPage);
        this.props.intro.setState({loaded:true});
    }
    showUrlPage() {
        this.props.intro.navigateToCommand(this.props.intro.state.command);
    }
    isEmpty(obj:any) {
        return Object.keys(obj).length === 0;
    }
    action(a:any){
        if( a.action_command === 'newsletter') {
            this.menuRef.current.setState({showSubscribeForm:true});
        }
        else  if( a.action_command === 'target_blank') {
            window.open(a.action_href,"_blank");
        }
        else  if( a.action_command === 'target_self') {
            window.open(a.action_href,"_self");
        }
        else
        alert(a.action_command);
    }

    buildBox(zeile:any,box:any):any {
        let cls="";
        if(box.cls) {
            cls = " "+box.cls+" ";
        }
        if( box.type === "headline") {
            let nopin = {'nopin': 'nopin'}
            return (<img {...nopin} alt={""} className={"headline box"+box.subtype+cls} src={box.src} key={box.id+"_image"}/>);
        }
        if( box.type === "stoerer_landscape") {
            let nopin = {'nopin': 'nopin'}
            return (<img {...nopin} alt={""} className={"stoerer_landscape"+cls} src={box.src} key={box.id+"_image"}/>);
        }
        if( box.type === "stoerer_portrait") {
            let nopin = {'nopin': 'nopin'}
            return (<img {...nopin} alt={""} className={"stoerer_portrait"+cls} src={box.src} key={box.id+"_image"}/>);
        }
        if( box.type === "image") {
            let nopin = {'nopin': 'nopin'};
            let img = (<img {...nopin} alt={""} className={"box"+box.subtype+cls} src={box.src} key={box.id+"_image"}/>);

            if( box.href ) {
                return (<a href={box.href} target={"_blank"}>{img}</a>);
            }
            return img;
        }
        if( box.type === "image4") {
            let nopin = {'nopin': 'nopin'}
            return (<img {...nopin} alt={""} className={"box"+box.subtype+cls} src={box.src} key={box.id+"_image"}/>);
        }
        let action:any=undefined;
        if( box.action_type ) {
            action = (<><br/><div className={"action_button"} onClick={(e)=>this.action(box)} key={box.id+"_action"} dangerouslySetInnerHTML={{__html: box.action_value}}/></>)
        }
        let rtl="ltr";
        if(box.rtl ) {
            rtl="rtl";
        }
        return (<div className={"box"+box.subtype +" "+box.type+cls} key={box.id+"_box"}>
        <div className={box.type?box.type:"text_box"+cls} key={box.id+"_view"} dangerouslySetInnerHTML={{__html: box.text}} dir={rtl}/>
        {action}
        </div>);
    }
    findFirstUnusedId(o:any){
        for(let i=1;i<=999;i++) {
            let s:string = i+"";
            while(s.length<3) s="0"+s;
            if( !o[s]){
                return s;
            }
        }
        return undefined;
    }
    addBox(zeile:any) {
    }
    editBox(box:any) {
    }
    removeBox(zeile:any,box:any) {
        for( let s in zeile.boxes) {
            if(zeile.boxes[s]===box) {
                delete zeile.boxes[s];
                if( this.isEmpty(zeile.boxes) ) {
                    //delete zeile;

                }
                break;
            }
        }
        //this.setState({data:this.state.data});
    }
    editZeile(zeile:any) {
        alert("editZeile");
    }
    buildZeile(zeile:any):any {
        let s=[];

        for(let child in zeile.children){
            if( zeile.children[child] )
                s.push(this.buildBox(zeile,zeile.children[child]));
        }
        if( s.length===0 ) {
            s.push(<><br/><br/></>);
        }
        return (<div className="zeile" key={zeile.id}>{s}
        {
            this.darfEditieren()?
              <>
                <div style={{position:"absolute",bottom:0,right:"100px"}} key={zeile.id+"_box"}>
        <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.editZeile(zeile)} key={zeile.id+"_edit"}>{iconEdit}</div>
          <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.addBox(zeile)} key={zeile.id+"_add"}>{iconAdd}</div>
          </div>
          </>:
            ""
        }
        </div>);
    }

    buildCredits(credits:any):any {
        let s=[];

        s.push(<hr/>);
        for(let child in credits.children){
            if( credits.children[child] )
                s.push(this.buildZeile(credits.children[child]));
        }
        if( s.length===0 ) {
            s.push(<><br/><br/></>);
        }
        return (<div className="credits" key={credits.id}>{s}
        {
            this.darfEditieren()?
              <>
                <div style={{position:"absolute",bottom:0,right:"100px"}} key={credits.id+"_box"}>
                    <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.editZeile(credits)} key={credits.id+"_edit"}>{iconEdit}</div>
                    <div style={{position:"relative",display:"inline",zIndex:"1005",color:"#808080",cursor:"pointer"}} onClick={(e:any)=>this.addBox(credits)} key={credits.id+"_add"}>{iconAdd}</div>
                </div>
            </>
            :
            ""
        }
        </div>);
    }
    showPerson(person:any,list:any){
        this.state.detailRef.current.setState({detailnode:person,siblings:list},
          ()=>{this.menuRef.current.actionMenu(person,true)});
    }
    showPrevPerson(currentperson:any) {
        let siblings = this.state.detailRef.current.state.siblings;
        let children = siblings.children;
        let elements=[];
        for( let p in children ){
            if( children[p].type==="person" ) {
                elements.push(children[p]);
            }
        }

        for( let i=0;i<elements.length; i++){
            if( elements[i].id === currentperson.id) {
                if( (i-1) >= 0  ) {
                    this.state.detailRef.current.setState({detailnode:elements[i-1],siblings:siblings});
                    return;
                }
                else {
                    this.state.detailRef.current.setState({detailnode:elements[elements.length-1],siblings:siblings});
                    return;
                }
            }
        }
    }
    showNextPerson(currentperson:any) {
        let siblings = this.state.detailRef.current.state.siblings;
        let children = siblings.children;
        let elements=[];
        for( let p in children ){
            if( children[p].type==="person" ) {
                elements.push(children[p]);
            }
        }

        for( let i=0;i<elements.length; i++){
            if( elements[i].id === currentperson.id) {
                if( (i+1) <= elements.length-1 ) {
                    this.state.detailRef.current.setState({detailnode:elements[i+1],siblings:siblings});
                    return;
                }
                else {
                    this.state.detailRef.current.setState({detailnode:elements[0],siblings:siblings});
                    return;
                }
            }
        }
    }
    person(l:any) {
        let prevnext=undefined;
        let siblings:any = this.state.detailRef.current.state.siblings;
        let children:any = [];
        if( siblings ) children=siblings.children;

        if( children && (children.length>1)){
            prevnext=(
              <>
                <span onClick={(e)=>this.showPrevPerson(l)}>{iconPrev}</span>
                <div className="portrait_headline">{l.headline}</div>
                <span onClick={(e)=>this.showNextPerson(l)}>{iconNext}</span>
                <br/>
                <br/>
              </>
            )}
        let credits=undefined;
        for(let c in l.children) {
            let cc=l.children[c];
            if( cc.type === "master" ) {

            }
            if( cc.type === "detail" ) {

            }
            if( cc.type === "credits" ) {
                credits=this.buildCredits(cc);
            }
        }
        let long_profil=undefined;
        if( l.long_profil) {
            long_profil=l.long_profil;
        }
        else {
            long_profil="";
        }
        return (
          <>
            <div className={"box13 portrait"}>
                <img style={{width:"100%",height:"auto"}} src={l.image}/>
            </div>
            <div className={"box23"}>
                {prevnext}
                <strong>{l.name?l.name:l.text}</strong><br/><br/>
                {l.position}
                {long_profil}
                <br/>
                <br/>
                <br/>
                {iconEnvelope}<a href={"mailto:"+l.email}> {l.email}</a>
                <br/>
                <br/>
                {l.telefon?
                      (<>{iconPhone}<a href={"tel:"+l.telefon}> {l.telefon}</a><br/><br/></>)
                 :
                    ""
                }

                <br/>
                <br/>
            </div>
            {credits}
          </>);
    }

    personenliste(l:any) {
        let p = [];
        let preview = Intro.urlParams.get("preview")?true:false;

        for(let c in l.children) {
            let cc=l.children[c];
            if( cc.type === "master" ) {

            }
            if( cc.type === "detail" ) {

            }
            if( cc.type === "credits" ) {
                p.push(this.buildCredits(cc));
                continue;
            }

            let cls = "";
            if( cc.cls ) {
                cc = " " + cc.cls +" ";
            }

            let pr: any=[],t:any=[],e:any=[];
            if( cc.position ) {
                pr.push((<span style={{cursor:"pointer"}} onClick={(e)=>this.showPerson(cc,l)}>{cc.position}</span>));
                pr.push(<br/>);
            }
            if( cc.image ) {
                //pr.push(<><img style={{cursor:"pointer"}} onClick={(e)=>this.showPerson(cc,l)} src={cc.image} width={"128"}/><br/></>);
            }
            if( cc.email ) {
                pr.push(iconEnvelope);
                let m=<> <a href={"mailto:"+cc.email}> {cc.email}</a></>;
                pr.push(m);
                pr.push(<br/>);
            }
            if( cc.telefon ) {
                pr.push(iconPhone);
                let t=<> <a href={"tel:"+cc.telefon}> {cc.telefon}</a></>;
                pr.push(t);
                pr.push(<br/>);
            }
            let b =
              (<div className={"box"+cc.subtype +" "+cc.type+cls} key={cc.id+"_box"}>
                <span style={{cursor:"pointer",textDecoration:"underline"}} onClick={(e)=>this.showPerson(cc,l)}>{cc.text}</span>
                <p>{pr}
                {t}
                {e}
                <br/>
                </p>
               </div>);
            p.push(b);
        }
        return p;
    }
    getLeaf(l:any, orientation:string, gap:string) {
        if( !l ) return "";

        let r=[];
        if( l.type === 'person') {
            r.push(this.person(l));
        }
        else
        if( l.type === 'master_menu_item') {
            if( l.action_command==="personenliste")
                r.push(this.personenliste(l));
            else
            if( l.action_command==="person")
                r.push(this.person(l));
        }
        else
        if( l.subtype === 'map') {
            r.push((<Map key={l.id+"_"+orientation+"_map"} orientation={orientation} gap={gap} map_id={l.type+"_map"}/>));
        }
        else
        if( l.subtype === 'PageEditor') {
            r.push((<PageEditor key={l.id+"_"+orientation+"_PageEditor"} />));
        }
        else
        if( l.subtype === 'video') {
            r.push((<Video intro={this.props.intro}
            vert={1920}
            horz={1080}
            url={l.url}
            key={l.type+"_"+orientation+"_video"} />));
        }
        else
        if( l.subtype === 'iframe') {
            if( !l.iframes )
                l.iframes={};
            l.iframes[l.id]=(<IIFrame key={l.id} url={l.url} orientation={orientation} gap={gap} iframe_id={l.type+"_iframe"}/>);
            r.push(l.iframes[l.id]);
        }
        else {
            if( l.type === 'middle' ) {
                r.push((
                  <div className="scrollerC zrb_backwhite">
                    <div style={{height:"45px"}}></div>
                    <EditablePage key={l.type} keyName={l.type} kleeblatt={this}  data={l} />
                  </div>
                ));
            }
            else
            if( l.type === "north" ) {
                r.push((
                  <div className={"scrollerN_" + KleeBlatt.northgap +" zrb_backwhite"} onClick={(e)=>this.click(e,"nop")} >
                    <EditablePage key={l.type} keyName={l.type} kleeblatt={this} data={l}/>
                  </div>
                ));
            }
            else
            if( l.type === "east" ) {
                r.push((
                  <div className={"scrollerE_"+KleeBlatt.eastgap +" zrb_backwhite"}>
                    <EditablePage key={l.type} keyName={l.type} kleeblatt={this} data={l}/>
                  </div>
                ));
            }
            else
            if( l.type === "south" ) {
                r.push((
                  <div className={"scrollerS_" + KleeBlatt.southgap+" zrb_backwhite"}>
                  <EditablePage key={l.type} keyName={l.type} kleeblatt={this} data={l}/>
                 </div>
            ));
            }
            else
            if( l.type === "west" ) {
                r.push((
                  <div className={"scrollerW_"+ KleeBlatt.westgap +" zrb_backwhite"}>
                  <EditablePage key={l.type} keyName={l.type} kleeblatt={this} data={l}/>
                </div>
            ));
            }
        }
        return r;
    }

    findActionCommandParameter(action_command_parameter:string):any{
        return this._findActionCommandParameter(this.state.menu,action_command_parameter);
    }
    _findActionCommandParameter(node:any,action_command_parameter:string):any{
        for(let c in node.children) {
            if( node.children[c].action_command_parameter===action_command_parameter) {
                return node.children[c];
            }
        }
        return undefined;
    }

    render() {
        let hasnorth=this.state.north!==undefined;
        let haseast=this.state.east!==undefined;
        let hassouth=this.state.south!==undefined;
        let haswest=this.state.west!==undefined;
        let hasmenu=this.state.menu!==undefined;
        let hasmiddle=this.state.middle!==undefined;

        let northpatch;
        if( haswest && hasnorth )
            northpatch = <div key={"north_back_patch"} className="northpatch zrb_backcolor2" id={"northpatch"} onClick={(e)=>this.click(e,"north")}></div>;

        let left_o = (!haswest?0:50);
        let top_o = (!hasnorth?0:50);
        let right_o = (!haseast?0:50);
        let bottom_o = (!hassouth?0:50);

        let middleclass;
        let style_m_left;
        let style_m_top;
        let style_m_width;
        let style_m_height;

        if( !this.props.leafs.toUpperCase().includes("F") ) {
            middleclass="middle"
            style_m_left = px(this.props.intro.state.vw+left_o);
            style_m_top = px(this.props.intro.state.vh+top_o);
            style_m_width = px(this.props.intro.state.vw-left_o-right_o);
            style_m_height = px(this.props.intro.state.vh-top_o-bottom_o);
        }
        else {
            middleclass = "middle_fixed";

            style_m_left = px(0);
            style_m_top = px(0);
            style_m_width = px(this.props.intro.state.vw);
            style_m_height = px(this.props.intro.state.vh);
        }

        let menu = undefined;
        let northmenu = undefined;
        if( hasmenu ) {
            if( hasnorth) {
                northmenu =<div style={{position:"absolute", bottom:"0", right:0, width:"130px", height:"50px"}}>
                  <KleeblattMenu ref={this.menuRef} kleeblatt={this} data={this.state.menu}/>
                </div>
            }
            else {
                menu =
                  <KleeblattMenu ref={this.menuRef} kleeblatt={this} data={this.state.menu}/>;
            }
        }

        let north_classname = "north_"+KleeBlatt.northgap;
        let east_classname = "east_"+KleeBlatt.eastgap;
        let south_classname = "south_"+KleeBlatt.southgap;
        let west_classname = "west_"+KleeBlatt.westgap;

        return (
            <>
            <div id={this.props.id} onClick={(e)=>this.click(e,"middle")} className="kleeblatt zrb_backcolor5"
                style={{left:px(-this.props.intro.state.vw
                            + (this.state.westUp*(this.props.intro.state.vw-50-KleeBlatt.westgap))
                            - (this.state.eastUp*(this.props.intro.state.vw-50-KleeBlatt.eastgap)) ),
                       top:px(-this.props.intro.state.vh
                            + (this.state.northUp*(this.props.intro.state.vh-50-KleeBlatt.northgap))
                            - (this.state.southUp*(this.props.intro.state.vh-50-KleeBlatt.southgap)) ),
                    width:px(3*this.props.intro.state.vw),
                    height:px(3*this.props.intro.state.vh),
                    display:this.state.visible?"block":"none"}}>
                {!hasmiddle?"":
                <div ref={"middle_container"}
                    className={middleclass}
                    onClick={(e)=>this.click(e,"middle")}
                    style={{
                        left:style_m_left,
                        top:style_m_top,
                        width:style_m_width,
                        height:style_m_height
                    }}
                    >
                    <div className="center westfalen_baut_kultur" onClick={(e)=>this.click(e,"middle")}
                        style={{
                            width:style_m_width,
                            height:style_m_height
                          }}
                    >
                        {this.getLeaf(this.state.middle, "middle", "")}
                    </div>
                    {menu}
                </div>
                }

                {!haswest?"":
                <div key={"west_container"}
                    className={west_classname+" zrb_backcolor2"}
                    id={"west"}
                    onClick={(e)=>this.click(e,"nop")}
                    style={{left:px(50),
                            top:px(this.props.intro.state.vh),
                            width:px(this.props.intro.state.vw),
                            height:px(this.props.intro.state.vh)}}>
                    {this.getLeaf(this.state.west, "west", KleeBlatt.westgap+"")}

                    <div key={"west_back"} className="west_label_background zrb_backcolor2" onClick={(e)=>this.click(e,"west")}/>
                    <div key={"west_text"} className="west_label zrb_backcolor2" onClick={(e)=>this.click(e,"west")}>
                        {this.state.west?this.state.west.text:""}
                    </div>
                </div>
                }

                {!hassouth?"":
                <div key={"south_container"}
                    className={south_classname + " zrb_backcolor4"}
                    id={"south"}
                    onClick={(e)=>this.click(e,"nop")}
                    style={{top:px(2*this.props.intro.state.vh - 50),
                            left:px(this.props.intro.state.vw),
                            width:px(this.props.intro.state.vw),
                            height:px(this.props.intro.state.vh-KleeBlatt.southgap)}}>
                    {this.getLeaf(this.state.south, "south", KleeBlatt.southgap+"")}
                    <div key={"south_back"} className="south_label_background zrb_backcolor3" onClick={(e)=>this.click(e,"south")}/>
                    <div key={"south_text"} className="south_label zrb_backcolor4" onClick={(e)=>this.click(e,"south")}>
                        <span className="is_landscape">{this.state.south?this.state.south.text:""}</span>
                        <span className="is_portrait">{this.state.south?(this.state.south.portrait_label?this.state.south.portrait_label:this.state.south.text):""}</span>
                    </div>
                </div>
                }

                {!haseast?"":
                <div key={"east_container"}
                    className={east_classname+" zrb_backcolor3"}
                    id={"east"}
                    onClick={(e)=>this.click(e,"nop")}
                    style={{left:px(this.props.intro.state.vw+this.props.intro.state.vw-50),
                          top:px(this.props.intro.state.vh),
                          width:px(this.props.intro.state.vw),
                          height:px(this.props.intro.state.vh)}}>
                    {this.getLeaf(this.state.east, "east", KleeBlatt.eastgap+"")}
                    <div key={"east_back"} className="east_label_background zrb_backcolor3" onClick={(e)=>this.click(e,"east")}/>
                    <div key={"east_text"} className="east_label zrb_backcolor3" onClick={(e)=>this.click(e,"east")}>
                        {this.state.east?this.state.east.text:""}
                    </div>
                </div>
                }

                {!hasnorth?"":
                <div key={"north_container"}
                    className={north_classname+" zrb_backcolor1"}
                    id={"north"}
                    onClick={(e)=>this.click(e,"nop")}
                    style={{width:px(this.props.intro.state.vw),
                    height:px(this.props.intro.state.vh-KleeBlatt.northgap)}}>
                    {this.getLeaf(this.state.north, "north", 0+"")}
                    <div key={"north_back"} className="north_label_background zrb_backcolor3" onClick={(e)=>this.click(e,"north")}/>
                    {northpatch}
                    <div key={"north_text"} className="north_label zrb_backcolor1" onClick={(e)=>this.click(e,"north")}>
                        {this.state.north?this.state.north.text:""}
                    </div>
                    {northmenu}
                </div>
                }
            </div>
            {this.state.master}
            {this.state.detail}
            </>
        )
    }
}

export default KleeBlatt;