import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {getURL} from "./helpers";

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import KleeblattMenu from "./KleeblattMenu";

const iconTimes = <FontAwesomeIcon icon={faTimes} />
declare const window: any;

interface NewsLetterProps {
  link_name:string;
  short:boolean;
  kleeblattMenu:KleeblattMenu;
}

interface NewsLetterState {
  email:string;
  err:any;
}

class NewsLetter extends React.Component <NewsLetterProps, NewsLetterState> {
    constructor(props:NewsLetterProps) {
      super(props);
      this.state={
        email:"",
        err:[]
      };
      this.showSubscribeForm=this.showSubscribeForm.bind(this);
      this.changedEmail=this.changedEmail.bind(this);
      this.sendSubscription=this.sendSubscription.bind(this);
      this.emailAdressInvalid=this.emailAdressInvalid.bind(this);
    }
    showSubscribeForm(v:boolean) {
      this.setState({err:[]});
      this.props.kleeblattMenu.setState({showSubscribeForm:v});
    }
    changedEmail(e:any) {
      this.setState({email:e.currentTarget.value});
      this.setState({err:[("")]});
    }
    emailAdressInvalid():boolean {
      return this.state.email.trim().length===0;
    }
    sendSubscription() {
      let url = getURL("subscribeNewsletter.php");

      let app = this;
      app.setState({err:[(<span className="process" dangerouslySetInnerHTML={{__html: "Abonnement wird angefordert."+"<br/>"}}></span>)]});

      fetch(url,{
          method: 'POST',
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({email:this.state.email})
        }
      ).then(function(response){
        return response.json();
      })
        .then(function(r) {
          if( r.status !== "ok" ) {
            app.setState({err:[(<span className="error" dangerouslySetInnerHTML={{__html: r.msg+"<br/>"}}></span>)]});
            return;
          }
          app.setState({err:[(<span className="success" dangerouslySetInnerHTML={{__html: r.msg+"<br/>"}}></span>)]});
        })
        .catch((error) => {
          app.setState({err:[(<span className="error" dangerouslySetInnerHTML={{__html: "Genereller Fehler. Versuche es bitte später erneut"+"<br/>"}}></span>)]});
        })

    }
    // dangerouslySetInnerHTML={{__html: this.state.show_text}}
    render() {
        return (
          <>
            <Modal show={this.props.kleeblattMenu.state.showSubscribeForm}
              onHide={()=>this.showSubscribeForm(false)}
              dialogClassName="modal-dialog modal-xl">
              <Modal.Header>
                <h1>Newsletter abonnieren</h1>
                <span onClick={(e)=>this.showSubscribeForm(false)}>{iconTimes}</span>
              </Modal.Header>

              <Modal.Body style={{padding:0}}>
                <div style={{margin:"20px"}}>
                  <p>Abonnieren Sie unseren Newsletter und erhalten Sie nützliche Tipps und Ratschläge rund um die Architektur in Westfalen.</p>

                  <label htmlFor={"abo_email"} className={"form-label bold"}>E-Mail Adresse</label>
                  <input type="email"
                          className="form-control"
                          onChange={(e:any)=>this.changedEmail(e)}
                          id="abo_email"
                          placeholder="name@beispiel.de"/>
                  <div style={{height:"0.5em"}}></div>
                  <div style={{float:"right"}}>
                    <Button variant="success" disabled={this.emailAdressInvalid()}  onClick={(e)=>this.sendSubscription()} size="sm">kostenlos abonnieren</Button>
                  </div>
                  {this.state.err}
                  <br/>
                  <p>Geben Sie bitte die E-Mail-Adresse an, an die der Newsletter gesendet werden soll.</p>
                  <p>Danach erhalten Sie in unregelmäßigen Abständen (also immer nur dann, wenn etwas wirklich Neues passiert) eine Nachricht von uns. Sie haben die Möglichkeit, den Newsletter jederzeit problemlos abzubestellen. </p>
                  <p>Bitte lesen Sie sich dazu auch unsere <a href="https://architektur-westfalen.de/datenschutz">Datenschutzerklärung</a> durch.</p>
                  <p>Um Fehlbestellungen zu vermeiden senden wir Ihnen zunächst eine E-Mail mit der Bitte um Bestätigung. So stellen wir  sicher, dass Sie den Newsletter tatsächlich erhalten möchten und die angegebene E-Mail-Adresse wirklich Ihnen gehört.</p>
              </div>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={(e)=>this.showSubscribeForm(false)} size="sm">Schließen</Button>
              </Modal.Footer>
            </Modal>
        </>
        )
    }
}

export default NewsLetter;